import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CourseOffer } from '@models/db/courseOffer';
import { CourseOfferSectionDataToShow } from '@models/logicModels/mallas/dataCourseOfferSection';
import { CourseSectionSubjectsComponent } from '../course-section-subjects/course-section-subjects.component';
import { OfferSectionGradesComponent } from '../offer-section-grades/offer-section-grades.component';
import { CommonModule } from '@angular/common';
import { OfferDetailsSubjectsOfferComponent } from '../offer-details-subjects-offer/offer-details-subjects-offer.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'offer-details',
  standalone: true,
  imports: [OfferDetailsSubjectsOfferComponent, CourseSectionSubjectsComponent, OfferSectionGradesComponent, CommonModule],
  templateUrl: './offer-details.component.html',
  styleUrl: './offer-details.component.css'
})
export class OfferDetailsComponent {

  @Input() selectedCourseOfferData!: CourseOffer;
  @Output() responseCloseDetails = new EventEmitter<void>();
  @Output() responseShowOfferModal = new EventEmitter<{isNew: boolean, index: number}>();

  public courseOfferDataToShow!: CourseOfferSectionDataToShow;

  public viewTabIndex: number = 1;
  protected textsToShow = {
    schoolTittle: 'Filtro por __',
  };

  constructor() {
    this.textsToShow.schoolTittle = environment.APP_DATA.SCHOOL_TEXT;
  }

  ngOnInit(): void {
    this.courseOfferDataToShow = {
      school: {
        name: this.selectedCourseOfferData.getSchoolAcronimAndSchoolName(),
      },
      courseOffer: {
        name: this.selectedCourseOfferData.NameCourseOfer!,
        type: this.selectedCourseOfferData.getTypeCourseOfferName(),
        quotas: `${this.selectedCourseOfferData.TotalPeople}`,
        registered: 'N/A', /** TODO: mostrar este dato */
        state: this.selectedCourseOfferData.getOferStateName(),
        peopleAcepted: 'N/A', /** TODO: mostrar este dato */
        category: 'Ed. Superio, Ed. Militar, Bienestar', /** TODO: mostrar este dato */
        training: 'Si', /** TODO: mostrar este dato */
        modality: 'Semi-presencial', /** TODO: mostrar este dato */
        startDate: '12/12/2022', /** TODO: mostrar este dato */
        finalDate: '12/12/2022', /** TODO: mostrar este dato */
        startInscriptionDate: '12/12/2022', /** TODO: mostrar este dato */
        finalInscriptionDate: '12/12/2022', /** TODO: mostrar este dato */
      },
      course: {
        name: 'courseNmae', /** TODO: mostrar este dato */
        state: 'courseState', /** TODO: mostrar este dato */
      }
    };
  }

  closeDetails() {    
    this.responseCloseDetails.emit();
  }

  showOfferModal() {
    this.responseShowOfferModal.emit({isNew: false, index: this.selectedCourseOfferData.id!});
  }

}
