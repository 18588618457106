<div class="modalMainContainer">
  <div class="myModal">
    <div class="modalHeader">
      <div class="modalHeader_info">
        <span class="headerMainInfo">{{ dataToShow.tittle }}</span><br>
        <span class="headerSecondaryInfo">Gestiona las materias del programa existente</span><br>
      </div>
      <div class="modalHeader_close">
        <button class="btnCallToAction" (click)="closeModal()">
          <em class="icon-i-close"></em>
        </button>
      </div>
    </div>
    <div class="modalTittle">
      <div class="modalTittle_left">
        <span class="tittleMainInfo">{{ dataToShow.subTittle }}</span><br>
        <span class="tittleSecondaryInfo">Comienza a {{ dataToShow.subTittleDesc }} materias para el programa</span><br>
      </div>
      <div class="modalTittle_right">
        <span class="tittleSecondaryInfo">Programa: <strong>{{ dataToShow.programa }}</strong></span>
      </div>
    </div>
    <div class="modalNavbar"></div>
    <div class="modalBody">
      
      @if (false) {
        @if (isNewData) {
          @for (areaToCreate of [0, 1]; track $index) {
            <div class="areaSection">
              <div class="areaSection_name">
                <span class="areaSection_label">Nombre del área</span><br>
                <!-- <input class="areaSection_info" type="text" [(ngModel)]="areaToCreate.name"> -->
              </div>
              <div class="areaSection_descript">
                <span class="areaSection_label">Descripción del área</span><br>
                <!-- <input class="areaSection_info" type="text" [(ngModel)]="areaToCreate.descript"> -->
              </div>
              <div class="areaSection_icons">
                <button class="invisibleBtn" (click)="closeModal()">
                  <em class="icon-i-trash"></em>
                </button>
              </div>
            </div>
          }
          <button class="invisibleBtn" (click)="closeModal()">
            <div class="addNewAreaSection">
              <span><em class="icon-i-add"></em> Agregar otra área</span>
            </div>
          </button>
        } @else {
          <div class="areaSection">
            <div class="areaSection_name">
              <span class="areaSection_label">Nombre del área</span><br>
              <!-- <input class="areaSection_info" type="text" [(ngModel)]="areaDataToEdit.name"> -->
            </div>
            <div class="areaSection_descript">
              <span class="areaSection_label">Descripción del área</span><br>
              <!-- <input class="areaSection_info" type="text" [(ngModel)]="areaDataToEdit.descript"> -->
            </div>
            <div class="areaSection_icons">
              
            </div>
          </div>
        }
      }

      <div class="containerSubjectData">
        <div class="containerSubjectData__subject">

          <div class="subjectProperty">
            <ui-input
              variant="bordered"
              label="Nombre de la materia"
              placeholder="Escriba una palabra para filtrar"
              [(ngModel)]="localSubjectData.NameSubject"
            />
          </div>

          <div class="subjectProperty">
            <ui-select variant="bordered" label="Semestres" defaultValue="0" [(ngModel)]="localSubjectData.SemesterNumber">
              <ui-select-content>
                <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                <ui-select-item value="1">1</ui-select-item>
                <ui-select-item value="2">2</ui-select-item>
                <ui-select-item value="3">3</ui-select-item>
                <ui-select-item value="4">4</ui-select-item>
                <ui-select-item value="5">5</ui-select-item>
                <ui-select-item value="6">6</ui-select-item>
                <ui-select-item value="7">7</ui-select-item>
                <ui-select-item value="8">8</ui-select-item>
                <ui-select-item value="9">9</ui-select-item>
                <ui-select-item value="10">10</ui-select-item>
                <ui-select-item value="11">11</ui-select-item>
                <ui-select-item value="12">12</ui-select-item>
              </ui-select-content>
            </ui-select>
          </div>

          <div class="subjectProperty">
            <ui-select variant="bordered" label="Tipo de calificación" defaultValue="0" [(ngModel)]="localSubjectData.TypeRecord">
              <ui-select-content>
                <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                <ui-select-item value="de 0 a 5">de 0 a 5</ui-select-item>
                <ui-select-item value="de 0 a 10">de 0 a 10</ui-select-item>
              </ui-select-content>
            </ui-select>
          </div>

          <div class="subjectProperty">
            <ui-input
              variant="bordered"
              label="Nota mínima para aprobar"
              placeholder="Nota mínima para aprobar"
              [(ngModel)]="localSubjectData.DesiredGrade"
            />
          </div>

          <div class="subjectProperty">
            <ui-select variant="bordered" label="Área académica" defaultValue="0" [(ngModel)]="localSubjectData.AcademicAreaID">
              <ui-select-content>
                <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                @for (academicAreasIterated of courseData.School?.AcademicAreas; track academicAreasIterated.id) {
                  <ui-select-item [value]="academicAreasIterated.id!">{{ academicAreasIterated.NameArea }}</ui-select-item>
                }
              </ui-select-content>
            </ui-select>
          </div>

          <div class="subjectProperty">
            <ui-select variant="bordered" label="Prerrequisito" defaultValue="-1" [(ngModel)]="localSubjectData.SubjectID_Requirement">
              <ui-select-content>
                <ui-select-item value="-1" [disabled]="true">Seleccionar</ui-select-item>
                <ui-select-item value="0">Ninguno</ui-select-item>
                @for (subjectIterated of courseData.Subjects; track subjectIterated.id) {
                  <ui-select-item [value]="subjectIterated.id!">{{ subjectIterated.NameSubject }}</ui-select-item>
                }
              </ui-select-content>
            </ui-select>
          </div>

          <div class="subjectProperty">
            <ui-select variant="bordered" label="Tipo" defaultValue="0" [(ngModel)]="localSubjectData.typeCreditID">
              <ui-select-content>
                <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                <ui-select-item value="1">Creditos 1</ui-select-item>
                <ui-select-item value="2">Creditos 2</ui-select-item>
                <ui-select-item value="3">Creditos 3</ui-select-item>
              </ui-select-content>
            </ui-select>
          </div>

          <div class="subjectProperty">
            <ui-input
              variant="bordered"
              label="Créditos"
              placeholder="Créditos"
              [(ngModel)]="localSubjectData.Creditos"
            />
          </div>

          <div class="subjectProperty">
            <ui-input
              variant="bordered"
              label="Hora presencial"
              placeholder="Hora presencial"
              [(ngModel)]="localSubjectData.HTI"
            />
          </div>

          <div class="subjectProperty">
            <ui-input
              variant="bordered"
              label="Hora asistida"
              placeholder="Hora asistida"
              [(ngModel)]="localSubjectData.HFH"
            />
          </div>

          <div class="subjectProperty">
            <ui-input
              variant="bordered"
              label="Hora independiente"
              placeholder="Hora independiente"
              [(ngModel)]="localSubjectData.HPM"
            />
          </div>

          <div class="subjectProperty">
            <ui-select variant="bordered" label="Máximo hora cátedras diarias" defaultValue="0" [(ngModel)]="localSubjectData.pendiente">
              <ui-select-content>
                <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                <ui-select-item value="1 hora cátedra = 45 minutos">1 hora cátedra = 45 minutos</ui-select-item>
                <ui-select-item value="1 hora cátedra = 45 minutos">1 hora cátedra = 60 minutos</ui-select-item>
              </ui-select-content>
            </ui-select>
          </div>

          <div class="subjectProperty">
            <ui-input
              variant="bordered"
              label="Porcentaje dentro del área"
              placeholder="Porcentaje dentro del área"
              [(ngModel)]="localSubjectData.pendiente"
            />
          </div>

          <div class="subjectProperty subjectProperty__large">
            <ui-input
              variant="bordered"
              label="Descripción del área"
              placeholder="Descripción del área"
              [(ngModel)]="localSubjectData.Description"
            />
          </div>

        </div>
        <div class="containerSubjectData__icon">
          <button class="invisibleBtn" (click)="confirmDeleteSubjectSelected()">
            <em class="icon-i-trash"></em>
          </button>
        </div>
      </div>
      


    </div>
    <div class="modalFooter">
      <button (click)="validateDataBeforeSendingReq()">{{ dataToShow.btnText }} materia</button>
    </div>
  </div>
</div>
