import { Component } from '@angular/core';
import { UiSelectComponent } from "../../../shared/components/ui/ui-select/ui-select.component";
import { UiSelectContentComponent } from "../../../shared/components/ui/ui-select/ui-select-content/ui-select-content.component";
import { UiSelectItemComponent } from "../../../shared/components/ui/ui-select/ui-select-item/ui-select-item.component";
import { UiInputComponent } from "../../../shared/components/ui/ui-input/ui-input.component";
import { enumAreasSubjectsTabsOptions } from '@models/logicModels/areasAndSubjects/enumList';
import { CommonModule } from '@angular/common';
import { ApiService } from '@services/api-service.service';
import { SweetalertService } from '@services/sweetalert.service';
import { forkJoin, take } from 'rxjs';
import { IEndpoint } from '@models/endpoint';
import { School } from '@models/db/school';
import { AreaSubjectsPageDataToShow, AreaSubjectsPageFilter, enumTypeModalIndexAreaSubjectPage } from '@models/logicModels/areasAndSubjects/areaSubjectsData';
import { environment } from 'src/environments/environment';
import { IDropdownItem } from '@components/ui/models/dropdown-item';
import { ManageAreaComponent } from '@components/areaSubject/modals/manage-area/manage-area.component';
import { ManageSubjectsComponent } from '@components/areaSubject/modals/manage-subjects/manage-subjects.component';
import { AcademicaArea } from '@models/db/AcademicaArea';
import { SubjectV2 } from '@models/db/SubjectV2';

@Component({
  selector: 'app-areas-and-subjects-page',
  standalone: true,
  imports: [
    // JhanComponents
    UiSelectComponent,
    UiSelectContentComponent,
    UiSelectItemComponent,
    UiInputComponent,
    // Modales
    ManageAreaComponent,
    ManageSubjectsComponent,
    // AngularComponents
    CommonModule,
    // Fin
  ],
  templateUrl: './areas-and-subjects-page.component.html',
  styleUrl: './areas-and-subjects-page.component.css'
})
export class AreasAndSubjectsPageComponent {

  public TABS_DATA = {
    ALL_OPTION: enumAreasSubjectsTabsOptions,
    SELECTED: enumAreasSubjectsTabsOptions.AREAS,
  }
  public FILTERS_DATA: AreaSubjectsPageFilter = {
    ALL_OPTION: {
      SCHOOLS: this._apiService.schools(),
      AREAS: []
    },
    SELECTED: {
      SCHOOL: -1,
      AREA: -1,
      TEXT: ''
    },
  }
  public DATA_TO_SHOW: AreaSubjectsPageDataToShow = {
    ALL_OPTION: {
      SCHOOLS: [],
    },
    SELECTED: {
      SCHOOL: [],
      AREA: [],
      SUBJECTS: [],
    },
  }
  public TEXT_TO_SHOW_IN_HTML = {
    labelFilterSchool: ''
  }
  protected MODALS_MANAGEMENT = {
    show: false,
    index: enumTypeModalIndexAreaSubjectPage.AREA_MODAL,
    allOptions: enumTypeModalIndexAreaSubjectPage,
    isNewData: false,
    data: {
      area: new AcademicaArea(),
      Subject: new SubjectV2(),
    }
  };

  constructor (
    private _apiService: ApiService,
    private _sweetalertService: SweetalertService,
  ) {
    this.TEXT_TO_SHOW_IN_HTML = {
      labelFilterSchool: `Filtro por ${environment.APP_DATA.SCHOOL_TEXT.toLowerCase()}`
    };
    this.getAllInitialData();
  };

  private getAllInitialData(): void {
    console.log('getAllInitialData: ');
    
    const dataToGetAllSchools = this.getFiltersToSchools();
    
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      FJ_ALL_SCHOOLS_LIST: this._apiService.get<School>( dataToGetAllSchools ).pipe( take(1) ),
    }).subscribe({
      next: (forkjoinResponse) => {
        console.log("getAllInitialData response:", forkjoinResponse);
        let { FJ_ALL_SCHOOLS_LIST } = forkjoinResponse;
        /** Guardar todos los datos de las escuelas */
        this.DATA_TO_SHOW.ALL_OPTION.SCHOOLS = FJ_ALL_SCHOOLS_LIST;
        this._apiService.ALL_SCHOOLS.set(FJ_ALL_SCHOOLS_LIST);
        /** Cerrar la modal */
        this._sweetalertService.swalClose();
        /** Cargo los filtros */
        this.applyFilters();
      },
      error: (err) => {
        console.log("getAllInitialData error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      },
      complete: () => {
        console.log("getAllInitialData complete:");
      }
    });
  }

  /** *************************************************** */
  /** Functions to get properties to get all Data from DB */
  /** *************************************************** */
  private getFiltersToSchools(): IEndpoint {
    return {
      path: "/Schools",
      filter: {
        order: 'NameTSchool ASC',
        where: { SchoolID: { inq: this._apiService.schools().map( item => item.id ) } },
        include: [
          { 
            relation: "AcademicAreas",
            scope: {
              order: 'NameArea ASC',
              include: [
                {
                  relation: 'SubjectsV2',
                  scope: {
                    order: 'nameSubject ASC',
                    include: [ { AcademicaArea: 'School' } ]
                  }
                },
                'School'
              ]
            }
          },
        ]
      }
    };
  }

  onSchoolSelectChange(optionSelected: IDropdownItem): void {
    this.FILTERS_DATA.SELECTED.SCHOOL = Number(optionSelected.value);
    this.applyFilters();
  }

  onAreaSelectChange(optionSelected: IDropdownItem): void {
    this.FILTERS_DATA.SELECTED.AREA = Number(optionSelected.value);
    this.applyFilters();
  }

  onStringQuerySelectChange(optionSelected: any): void {
    this.FILTERS_DATA.SELECTED.TEXT = optionSelected.target.value;
    this.applyFilters();
  }

  private applyFilters(): void {
    this.DATA_TO_SHOW.SELECTED.SCHOOL = this.DATA_TO_SHOW.ALL_OPTION.SCHOOLS.filter(schoolIterated => (
      (
        this.FILTERS_DATA.SELECTED.SCHOOL == -1 ||
        this.FILTERS_DATA.SELECTED.SCHOOL == schoolIterated.id
      )
    ));
    this.DATA_TO_SHOW.SELECTED.AREA = [];
    this.DATA_TO_SHOW.SELECTED.SCHOOL.forEach( schoolIterated => {
      if (!schoolIterated.AcademicAreas) return;
      schoolIterated.AcademicAreas.forEach(areaIterated => {
        if (
          this.FILTERS_DATA.SELECTED.TEXT.trim() == '' ||
          areaIterated.NameArea!.toLowerCase().includes(this.FILTERS_DATA.SELECTED.TEXT.trim().toLowerCase())
        ) this.DATA_TO_SHOW.SELECTED.AREA.push(areaIterated);
      });
    });
    this.DATA_TO_SHOW.SELECTED.SUBJECTS = [];
    this.DATA_TO_SHOW.SELECTED.AREA.forEach( areaIterated => {
      if (!areaIterated.SubjectsV2) return;
      areaIterated.SubjectsV2.forEach(subjectIterated => {
        if (
          this.FILTERS_DATA.SELECTED.TEXT.trim() == '' ||
          subjectIterated.nameSubject!.toLowerCase().includes(this.FILTERS_DATA.SELECTED.TEXT.trim().toLowerCase())
        ) this.DATA_TO_SHOW.SELECTED.SUBJECTS.push(subjectIterated);
      });
    });


    switch (this.TABS_DATA.SELECTED) {
      case this.TABS_DATA.ALL_OPTION.AREAS:
        if (this.DATA_TO_SHOW.SELECTED.AREA.length == 0) this._sweetalertService.swalError('', 'No hay datos para mostrar', ()=>{});
        break;
      case this.TABS_DATA.ALL_OPTION.SUBJECTS:
        if (this.DATA_TO_SHOW.SELECTED.SUBJECTS.length == 0) this._sweetalertService.swalError('', 'No hay datos para mostrar', ()=>{});
        break;
    }
    console.log('DATA_TO_SHOW', this.DATA_TO_SHOW);
  }

  /** *************************************************** */

  protected validateDataToShowAreaModal(): void {
    this.MODALS_MANAGEMENT.index = this.MODALS_MANAGEMENT.allOptions.AREA_MODAL;
    this.MODALS_MANAGEMENT.isNewData = true;
    this.MODALS_MANAGEMENT.show = true;
  }

  protected editAreaOnModal(areaSelected: AcademicaArea): void {
    this.MODALS_MANAGEMENT.data.area = areaSelected;
    this.MODALS_MANAGEMENT.index = this.MODALS_MANAGEMENT.allOptions.AREA_MODAL;
    this.MODALS_MANAGEMENT.isNewData = false;
    this.MODALS_MANAGEMENT.show = true;
  }

  protected validateDataToShowSubjectModal(): void {
    this.MODALS_MANAGEMENT.index = this.MODALS_MANAGEMENT.allOptions.SUBJECT_MODAL;
    this.MODALS_MANAGEMENT.isNewData = true;
    this.MODALS_MANAGEMENT.show = true;
  }

  protected editSubjectOnModal(subjectSelected: SubjectV2): void {
    this.MODALS_MANAGEMENT.data.Subject = subjectSelected;
    this.MODALS_MANAGEMENT.index = this.MODALS_MANAGEMENT.allOptions.SUBJECT_MODAL;
    this.MODALS_MANAGEMENT.isNewData = false;
    this.MODALS_MANAGEMENT.show = true;
  }

  protected closeModals(): void {
    this.MODALS_MANAGEMENT.show = false;
  }

  protected closeModalsAndUpdateData(): void {
    this.MODALS_MANAGEMENT.show = false;
    this.getAllInitialData();
  }


}
