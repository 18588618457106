export class AcademicPerformanceOffer {
  id?: number;
  originAcademicPerformanceID?: number;
  descripionPerformance?: string;
  subjectV2OfferID?: number;
  hexaColor?: string;
  performanceNum?: number;
  percentage?: number;
  midTermV2ID?: number;

  /** ********* */
  /** RELATIONS */
  /** ********* */

}
