<div class="input-container">
  <div class="input-field" [ngClass]="combinedClasses()" (click)="clickedInside.set(true); inputElement.focus()">
    <label class="field-label">{{ label() }}</label>
    <div class="input-field__content">
      <div class="decorator"><ng-content select="[start-decorator]" /></div>
      <input class="ui-input" autocomplete="off" [value]="finalValue() || null" [type]="type()"
        [placeholder]="placeholder() || ''" (input)="onChange($event)" (blur)="onTouch()()"
        #inputElement />
    </div>
  </div>
</div>