import { Routes } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { msDataResolver } from './resolvers/ms-data-resolver.resolver';
import { MallasPageComponent } from './pages/private/mallas-page/mallas-page.component';
import { AreasAndSubjectsPageComponent } from './pages/private/areas-and-subjects-page/areas-and-subjects-page.component';
export const routes: Routes = [
  {
    path: 'manage-academic-programs',
    resolve:{
      msData: msDataResolver
    },
    children: [
      { path: 'mallas', component: MallasPageComponent },
      { path: 'areasAndSubjects', component: AreasAndSubjectsPageComponent },
      { path: '**', component: EmptyRouteComponent }, /** TODO: Redirect to home! */
    ],
  },
];
