<div class="modalMainContainer">
  <div class="myModal">
    <div class="modalHeader">
      <div class="modalHeader_info">
        <span class="headerMainInfo">{{ dataToShow.tittle }}</span><br>
        <span class="headerSecondaryInfo">{{ dataToShow.subTittle }} todos los detalles de la oferta</span><br>
        <!-- <span class="headerThirthInfo">Este curso tiene 0 ofertas</span> -->
      </div>
      <div class="modalHeader_close">
        <button class="btnCallToAction" (click)="closeModal()">
          <em class="icon-i-close"></em>
        </button>
      </div>
    </div>
    <div class="modalNavbar">
      <div class="tabsContainer">
        <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': MANAGE_TABS.index == MANAGE_TABS.allOptions.DETAILS }" (click)="MANAGE_TABS.index = MANAGE_TABS.allOptions.DETAILS">Detalles de la oferta</button>
        <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': MANAGE_TABS.index == MANAGE_TABS.allOptions.NOTES }" (click)="MANAGE_TABS.index = MANAGE_TABS.allOptions.NOTES">Configuración de notas</button>
        <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': MANAGE_TABS.index == MANAGE_TABS.allOptions.SUBJECTS }" (click)="MANAGE_TABS.index = MANAGE_TABS.allOptions.SUBJECTS">Asignar materias</button>
        <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': MANAGE_TABS.index == MANAGE_TABS.allOptions.PERFORMANCES }" (click)="MANAGE_TABS.index = MANAGE_TABS.allOptions.PERFORMANCES">Desempeños</button>
      </div>
    </div>
    <div class="modalBody">
      @switch ( MANAGE_TABS.index ) {
        @case ( MANAGE_TABS.allOptions.DETAILS ) {
          <div class="containerOfferDetails">
            <div class="courseProperty courseProperty__100"><!-- Input Nombre de la oferta -->
              <ui-input
                variant="bordered"
                label="Nombre de la oferta"
                placeholder="Nombre de la oferta"
                [(ngModel)]="localOfferData.NameCourseOfer"
              />
            </div>
            <div class="courseProperty courseProperty__50"><!-- Select Categoría -->
              <ui-select variant="bordered" label="Categoría" defaultValue="0" [(ngModel)]="localOfferData.Altitud"> <!-- TODO: Validar nombre campo -->
                <ui-select-content>
                  <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                  @for (categoryIterated of ALL_CATEGORIES; track categoryIterated.id) {
                    <ui-select-item [value]="categoryIterated.id!">{{ categoryIterated.NameCategoryCourse }}</ui-select-item>
                  }
                </ui-select-content>
              </ui-select>
            </div>
            <div class="courseProperty courseProperty__50"><!-- Select Tipo -->
              <ui-select variant="bordered" label="Tipo" defaultValue="0" [(ngModel)]="localOfferData.TypeCourseID">
                <ui-select-content>
                  <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                  @for (typeCourseIterated of ALL_TYPE_COURSES; track typeCourseIterated.id) {
                    <ui-select-item [value]="typeCourseIterated.id!">{{ typeCourseIterated.NameTypeCourse }}</ui-select-item>
                  }
                </ui-select-content>
              </ui-select>
            </div>
            <div class="courseProperty courseProperty__50"><!-- Select Entrenamiento -->
              <ui-select variant="bordered" label="Entrenamiento" defaultValue="0" [(ngModel)]="localOfferData.TypeTrainingID">
                <ui-select-content>
                  <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                  <!-- TODO: VAILIDAR -->
                  @for (typeTrainingIterated of ALL_TYPE_TRAINING; track typeTrainingIterated.id) {
                    <ui-select-item [value]="typeTrainingIterated.id!">{{ typeTrainingIterated.NameTraining }}</ui-select-item>
                  }
                </ui-select-content>
              </ui-select>
            </div>
            <div class="courseProperty courseProperty__50"><!-- Input Cupos -->
              <ui-input
                variant="bordered"
                label="Cupos"
                placeholder="Cupos"
                [(ngModel)]="localOfferData.TotalPeople"
              />
            </div>
            <div class="courseProperty courseProperty__50"><!-- Select Modalidad -->
              <ui-select variant="bordered" label="Modalidad" defaultValue="0" [(ngModel)]="localOfferData.ModalityID">
                <ui-select-content>
                  <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                  <!-- TODO: VAILIDAR -->
                  @for (modalityIterated of ALL_MODALITIES; track modalityIterated.id) {
                    <ui-select-item [value]="modalityIterated.id!">{{ modalityIterated.nameModality }}</ui-select-item>
                  }
                </ui-select-content>
              </ui-select>
            </div>
            <div class="courseProperty courseProperty__50"><!-- Select ¿Oferta activa? -->
              <ui-select variant="bordered" label="¿Oferta activa?" defaultValue="0" [(ngModel)]="localOfferData.IsActive">
                <ui-select-content>
                  <ui-select-item value="0">No</ui-select-item>
                  <ui-select-item value="1">Si</ui-select-item>
                </ui-select-content>
              </ui-select>
            </div>
            <div class="courseProperty courseProperty__50"><!-- Select Estado de la oferta -->
              <ui-select variant="bordered" label="Estado de la oferta" defaultValue="0" [(ngModel)]="localOfferData.OferState">
                <!-- TODO: Traer datos desde oferState -->
                <ui-select-content>
                  <ui-select-item value="0">Fuera de Cronograma</ui-select-item>
                  <ui-select-item value="1">En Cronograma</ui-select-item>
                </ui-select-content>
              </ui-select>
            </div>
            <div class="courseProperty courseProperty__50"><!-- Input Fecha de inicio -->
              <ui-date-input
                variant="bordered"
                label="Fecha de inicio"
                [(ngModel)]="localOfferData.RealSince"
              />
            </div>
            <div class="courseProperty courseProperty__50"><!-- Input Fecha final -->
              <ui-date-input
                variant="bordered"
                label="Fecha final"
                [(ngModel)]="localOfferData.RealUntil"
              />
            </div>
            <div class="courseProperty courseProperty__50"><!-- Input Inicio de inscripciones -->
              <ui-date-input
                variant="bordered"
                label="Inicio de inscripciones"
                [(ngModel)]="localOfferData.InscriptStart"
              />
            </div>
            <div class="courseProperty courseProperty__50"><!-- Input Termino de inscripciones -->
              <ui-date-input
                variant="bordered"
                label="Termino de inscripciones"
                [(ngModel)]="localOfferData.InscriptEnd"
              />
            </div>
          </div>
        }
        @case ( MANAGE_TABS.allOptions.NOTES ) {
          <div class="containerOfferNotes">
            <div class="containerOfferNotes_tittle">
              <span class="tittle">Configuración de notas</span><br>
              <span class="subtittle">Gestiona las características de evaluación</span>
            </div>
            @for (item of AllLocalInfoMidTerms; track $index; let idx = $index) {
              <div class="containerOfferNotes_content">
                <div class="courseProperty courseProperty__50"><!-- Input Nombre de la nota -->
                  <ui-input
                    variant="bordered"
                    label="Nombre de la nota"
                    placeholder="Nombre de la nota"
                    [(ngModel)]="item.description"
                  />
                </div>
                <div class="courseProperty courseProperty__50"><!-- Input Porcentaje -->
                  <ui-input
                    variant="bordered"
                    label="Porcentaje"
                    placeholder="Porcentaje"
                    [(ngModel)]="item.percentage"
                  />
                </div>
                <div class="courseProperty__50">
                  <span class="tittleSubSection">Duración del Periodo</span>
                </div>
                <div class="courseProperty__50">
                  <span class="tittleSubSection">Cargue de Notas</span>
                </div>
                <div class="courseProperty"><!-- Input Inicio del periodo -->
                  <ui-date-input
                    variant="bordered"
                    label="Inicio del periodo"
                    [(ngModel)]="item.midTermStart"
                  />
                </div>
                <div class="courseProperty"><!-- Input Finalización del periodo -->
                  <ui-date-input
                    variant="bordered"
                    label="Finalización del periodo"
                    [(ngModel)]="item.midTermEnd"
                  />
                </div>
                <div class="courseProperty"><!-- Input Inicio de cargue de notas -->
                  <ui-date-input
                    variant="bordered"
                    label="Inicio de cargue de notas"
                    [(ngModel)]="item.midTermRecordStart"
                  />
                </div>
                <div class="courseProperty"><!-- Input Finalización cargue de notas -->
                  <ui-date-input
                    variant="bordered"
                    label="Finalización cargue de notas"
                    [(ngModel)]="item.midTermRecordEnd"
                  />
                </div>
                <div class="courseProperty__100">
                  <span class="tittleSubSection">Recuperación</span>
                </div>
                <div class="courseProperty"><!-- Input Inicio de recuperación -->
                  <ui-date-input
                    variant="bordered"
                    label="Inicio de recuperación"
                    [(ngModel)]="item.remedialGradeStart"
                  />
                </div>
                <div class="courseProperty"><!-- Input Finalización de recuperación -->
                  <ui-date-input
                    variant="bordered"
                    label="Finalización de recuperación"
                    [(ngModel)]="item.remedialGradeEnd"
                  />
                </div>
                <div class="courseProperty courseProperty__invisible">
                  <button class="invisibleBtn" (click)="confirmDeleteNotesSectionSelected(idx)">
                    <em class="icon-i-trash"></em>
                  </button>
                </div><!-- Vacio -->
                <div class="courseProperty courseProperty__invisible"></div><!-- Vacio -->
              </div>
            }
          </div>
          <button class="invisibleBtn" (click)="addNewNoteSection()">
            <div class="addNewNotesSection">
              <span><em class="icon-i-add"></em> Agregar</span>
            </div>
          </button>
        }
        @case ( MANAGE_TABS.allOptions.SUBJECTS ) {
          <div class="containerCourseSubjectData">
            @for (areaIterate of areaManagementToShow; track areaIterate.idArea!) {
              <div class="containerCourseSubjectData_areaName">
                <span>{{ areaIterate.name }}</span>
              </div>
              @for (subjectIterated of areaIterate.allSubjectList; track subjectIterated.idSubject) {
                <button (click)="subjectIterated.isSelected = !subjectIterated.isSelected" class="invisibleBtn btnContainerBox">
                  <div class="boxCourseSubject" [ngClass]="{ 'boxCourseSubject_selected': subjectIterated.isSelected}">
                    <div class="boxCourseSubject__square"></div> <span>{{ subjectIterated.subjectData.nameSubject }}</span>
                  </div>
                </button>
              }
            } @empty {
              No hay áreas seleccionadas
            }
          </div>
        }
        @case ( MANAGE_TABS.allOptions.PERFORMANCES ) {

          @for (areaIterate of areaManagementToShow; track areaIterate.idArea!) {
            @for (subjectIterated of areaIterate.allSubjectList; track subjectIterated.idSubject) {
              @if (subjectIterated.isSelected) {
                <div class="containerCourseSubjectData_areaName">
                  <span>{{ areaIterate.name }}</span>
                </div>
                @if (subjectIterated.subjectData.isGradeByTask) {
                  <div class="containerOfferPerromances">
                    @for (acadPerfIterated of subjectIterated.academicPerformances; track acadPerfIterated.id; let idx = $index) {
                      <div class="containerOfferPerromances_temporalView">
                        <input class="indexNotesSections" [(ngModel)]="acadPerfIterated.performanceNum">
                        <div class="temporalView_section">
                          <ui-input
                            variant="bordered"
                            label="Descripción del desempeño"
                            placeholder="Descripción del desempeño"
                            [(ngModel)]="acadPerfIterated.descripionPerformance"
                          />
                        </div>
                        <div class="temporalView_section">
                          <ui-input
                            variant="bordered"
                            label="Porcentaje del desempeño"
                            placeholder="Porcentaje del desempeño"
                            [(ngModel)]="acadPerfIterated.percentage"
                          />
                        </div>
                        <div class="containerCourseNotesConfigData_deleteIcon">
                          <button class="invisibleBtn" (click)="confirmDeleteAcademicPerformanceSectionSelected(subjectIterated, idx)">
                            <em class="icon-i-trash"></em>
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                  <!--  -->
                  <button class="invisibleBtn" (click)="addNewAcademicPerformanceSection(subjectIterated)">
                    <div class="addNewNotesSection">
                      <span><em class="icon-i-add"></em> Agregar</span>
                    </div>
                  </button>
                } @else {
                  <span>La configuración de esta materia no permite asignar desempeños.</span>
                }
              }
            }
          }
          @if (false) { <!-- TODO: Eliminar despues de confirmar diseño -->
            <div class="containerOfferPerromances">
              <div class="containerOfferPerromances_temporalView">
                <div class="temporalView_section">
                  <span class="tempTittle">Nombre del desempeño</span><br>
                  <span class="tempContent">Desempeño 2</span>
                </div>
                <div class="temporalView_section">
                  <span class="tempTittle">Porcentaje del desempeño</span><br>
                  <span class="tempContent">20%</span>
                </div>
                <div class="temporalView_section">
                  <span class="tempTittle">Cantidad de actividades</span><br>
                  <span class="tempContent">3</span>
                </div>
                <div class="temporalView_section">
                  <span class="tempTittle">Descripción del área</span><br>
                  <span class="tempContent">THE STUDENT RECOGNIZES, CLASSIFIES AND USES NUMBERS FROM 1 TO 10, COLORS, AND BASIC VOCABULARY ABOUT FARM ANIMALS.</span>
                </div>
              </div>
            </div>
          }
        }
      }
    </div>
    <div class="modalFooter">
      <button (click)="validateDataBeforeSendingReq()">{{ dataToShow.btnText }} área</button>
    </div>
  </div>
</div>
