import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IEndpoint } from '@models/endpoint';
import { IUser } from '@models/users';
import { ISchool } from '@models/school';
import { TypeCourseDuration } from '@models/db/TypeCourseDuration';
import { Category } from '@models/db/category';
import { TypeCourse } from '@models/db/typeCourse';
import { TypeTraining } from '@models/db/TypeTraining';
import { Modality } from '@models/db/Modality';
import { ICapabilities, ICapabilityActions } from '@models/logicModels/Other/CapabilityActions';
import { School } from '@models/db/school';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _http = inject(HttpClient);

  public userRoles = signal<ICapabilities[]>([]);
  public userInfo = signal<IUser | null>(null);
  public schools = signal<ISchool[]>([]);
  
  /** ******************************* */
  /** BEGIN MALLAS DATA (DavidTrompa) */
  /** ******************************* */
  public ALL_TYPE_COURSES_DURATION = signal<TypeCourseDuration[]>([]);
  public ALL_TYPE_COURSES = signal<TypeCourse[]>([]);
  public ALL_CATEGORIES_LIST = signal<Category[]>([]);
  public ALL_TYPE_TRAINING = signal<TypeTraining[]>([]);
  public ALL_MODALITIES = signal<Modality[]>([]);
  public ALL_SCHOOLS = signal<School[]>([]);
  /** ******************************* */
  /** _END_ MALLAS DATA (DavidTrompa) */
  /** ******************************* */

  private BACK_V2 = environment.URL_BACK_V2;


  /**
   * Validate current user roles
   * @param roles
   * @returns
   */
  public hasCapability(allowed: ICapabilityActions): boolean {
    if(!this.userRoles()) return false;

    const allowedKeys = Object.keys(allowed) as (keyof ICapabilityActions)[];
    return this.userRoles()!.some(capabilities => allowedKeys.some(key => capabilities[key] === allowed[key]));
  }

  private buildUrl<T>({ path, filter }: IEndpoint<T>): string {
    return `${this.BACK_V2}${path}${
      filter ? `?filter=${JSON.stringify(filter)}` : ''
    }`;
  }

  public get<T>(endpointInfo: IEndpoint<T>): Observable<T[]> {
    return this._http.get<T[]>(this.buildUrl(endpointInfo));
  }

  public put<T>(endpointInfo: IEndpoint<T>): Observable<T> {
    return this._http.put<T>(this.buildUrl(endpointInfo), endpointInfo.data);
  }

  public patch<T>(endpointInfo: IEndpoint<T>): Observable<T> {
    return this._http.patch<T>(this.buildUrl(endpointInfo), endpointInfo.data);
  }

  public post<T>(endpointInfo: IEndpoint<T>): Observable<T> {
    return this._http.post<T>(this.buildUrl(endpointInfo), endpointInfo.data);
  }

  public delete<T>(endpointInfo: IEndpoint<T>): Observable<T> {
    return this._http.delete<T>(this.buildUrl(endpointInfo));
  }
}