import { AcademicaAreaOffer } from "./AcademicaAreaOffer";
import { Course } from "./course";
import { OfferState } from "./offerState";
import { School } from "./school";
import { SubjectV2Offers } from "./SubjectV2Offers";
import { TypeCourse } from "./typeCourse";

export class CourseOffer {
  NameCourseOfer?: string;
  CourseID?: number;
  IsActive?: boolean;
  Since?: string;
  Until?: string;
  RealSince?: Date;
  RealUntil?: Date;
  SchoolID?: number;
  Price?: number;
  TypeCourseID?: number;
  IsSemestralize?: boolean;
  DepartamentID?: number;
  TypeTrainingID?: number;
  Altitud?: string;
  Longitud?: string;
  InscriptStart?: Date;
  InscriptEnd?: Date;
  SemesterNumber?: number;
  ModalityID?: number;
  TotalPeople?: number;
  OferState?: number;
  Profiles?: string;
  Forces?: string;
  ForeignPeople?: number;
  IsDirectiva?: boolean;
  id?: number;
  approvedAt?: string;
  approvedBy?: number;
  createdAt?: string;
  createdBy?: number;
  infoMidterms?: string;
  year?: number;
  version?: number;

  /** ********* */
  /** RELATIONS */
  /** ********* */
  school?: School;
  TypeCourseOFer?: TypeCourse;
  oferState?: OfferState;
  course?: Course;
  SubjectV2Offers?: SubjectV2Offers[];
  AcademicaAreaOffers?: AcademicaAreaOffer[];

  // typeTraining
  // modality: Modality;


  constructor (data?: Partial<CourseOffer>) {
    Object.assign(this, data);
  }

  /** ******* */
  /** METHODS */
  /** ******* */

  /**
   * **Note:**
   * if you dont have "School" relation, you will get a default response
   */
  public getSchoolAcronimAndSchoolName(): string {
    let response = 'Cargando nombre...';
    if ( this.school ) response = `${this.school.SchoolAcronim} - ${this.school.NameTSchool}`;
    return response;
  }

  /**
   * **Note:**
   * if you dont have "TypeCourseOFer" relation, you will get a default response
   */
  public getTypeCourseOfferName(): string {
    let response = 'N/A';
    if ( this.TypeCourseOFer ) response = `${this.TypeCourseOFer.NameTypeCourse}`;
    return response;
  }

  /**
   * **Note:**
   * if you dont have "oferState" relation, you will get a default response
   */
  public getOferStateName(): string {
    let response = 'Cargando estado';
    if ( this.oferState ) response = `${this.oferState.nameState}`;
    return response;
  }

}
