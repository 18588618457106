<div class="mainContainerAreaAndSubjectsPage">
  <!-- BOTONES SUPERIORES -->
  <div class="mainContainerAreaAndSubjectsPage_upperBtns">
    <div class="upperBtns_left">
      <button class="btnNavigateSections" [ngClass]="{ 'btnNavigateSections_selected': TABS_DATA.SELECTED == TABS_DATA.ALL_OPTION.AREAS }" (click)="TABS_DATA.SELECTED = TABS_DATA.ALL_OPTION.AREAS">AREAS</button>
      <button class="btnNavigateSections" [ngClass]="{ 'btnNavigateSections_selected': TABS_DATA.SELECTED == TABS_DATA.ALL_OPTION.SUBJECTS}" (click)="TABS_DATA.SELECTED = TABS_DATA.ALL_OPTION.SUBJECTS">MATERIAS</button>
    </div>
    <div class="upperBtns_right"></div>
  </div>
  <!-- FILTROS -->
  <div class="mainContainerAreaAndSubjectsPage_filtersSection">
    <div>
      <ui-select [label]="TEXT_TO_SHOW_IN_HTML.labelFilterSchool" defaultValue="-1" (onValueChange)="onSchoolSelectChange($event)">
        <ui-select-content>
          @if (FILTERS_DATA.ALL_OPTION.SCHOOLS.length > 0) {
            <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
            <ui-select-item value="-1">Todos</ui-select-item>
            @for (schoolIterated of FILTERS_DATA.ALL_OPTION.SCHOOLS; track schoolIterated.id) {
              <ui-select-item [value]="schoolIterated.id!">{{ schoolIterated.NameTSchool }}</ui-select-item>
            }
          } @else {
            <ui-select-item value="-1">Todos</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
    </div>
    @if ( TABS_DATA.SELECTED == TABS_DATA.ALL_OPTION.SUBJECTS ) {
      <div>
        <ui-select label="Filtro por área" defaultValue="-1" (onValueChange)="onAreaSelectChange($event)">
          <ui-select-content>
            @if (FILTERS_DATA.ALL_OPTION.SCHOOLS.length > 0) {
              <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
              <ui-select-item value="-1">Todos</ui-select-item>
              @for (areaIterated of FILTERS_DATA.ALL_OPTION.AREAS; track areaIterated.id) {
                <ui-select-item [value]="areaIterated.id!">{{ areaIterated.NameArea }}</ui-select-item>
              }
            } @else {
              <ui-select-item value="-1">Todos</ui-select-item>
            }
          </ui-select-content>
        </ui-select>
      </div>
    }
    <div>
      <ui-input
        variant="flat"
        label="Filtro por texto"
        placeholder="Filtrar por texto"
        (change)="onStringQuerySelectChange($event)"
      />
    </div>
  </div>
  <!-- CONTENIDO -->
  <div class="mainContainerAreaAndSubjectsPage_resultsCards">
    @switch (TABS_DATA.SELECTED) {
      @case (TABS_DATA.ALL_OPTION.AREAS) {
        <div class="containerSectionAreas">
          <div class="sectionAreas_tittle">
            <span>Áreas</span>
            <button class="btnCallToAction" (click)="validateDataToShowAreaModal()">Crear área</button>
          </div>
          @for (areaIterated of DATA_TO_SHOW.SELECTED.AREA; track areaIterated.id) {
            <div class="sectionAreas_areaData">
              <div class="data">
                <strong class="areaName">{{ areaIterated.NameArea }} </strong><span class="schoolName">{{ areaIterated.School?.NameTSchool }}</span><br>
                <strong>Materias registradas:</strong> {{ areaIterated.SubjectsV2?.length }} <br>
                <strong>Descripción:</strong> {{ areaIterated.Description }} <br>
              </div>
              <div class="icon">
                <button class="invisibleBtn" (click)="editAreaOnModal(areaIterated)">
                  <em class="icon-i-pen"></em>
                </button>
              </div>
            </div>
          } @empty {
            <!-- TODO: Crear estilo global -->
            <div class="noDataToShowContainer">
              <em class="icon-i-add"></em><br><!-- TODO: Update icon by Daniela/Luis -->
              <span>No hay áreas disponibles.<br>Comienza creando una nueva.</span>
            </div>
          }
        </div>
      }
      @case (TABS_DATA.ALL_OPTION.SUBJECTS) {
        <div class="containerSectionSubjects">
          <div class="sectionSubjects_tittle">
            <span>Materias</span>
            <button class="btnCallToAction" (click)="validateDataToShowSubjectModal()">Agregar materia</button>
          </div>
          @for (subjectIterated of DATA_TO_SHOW.SELECTED.SUBJECTS; track subjectIterated.id) {
            <div class="sectionSubjects_areaData">
              <div class="data">
                <strong class="subjectName">{{ subjectIterated.nameSubject }} </strong><span class="schoolName">{{ subjectIterated.AcademicaArea?.NameArea }}</span><span class="schoolName">{{ subjectIterated.AcademicaArea?.School?.NameTSchool }}</span><br>
                <strong>Descripción:</strong> {{ subjectIterated.description }} <br>
              </div>
              <div class="icon">
                <button class="invisibleBtn" (click)="editSubjectOnModal(subjectIterated)">
                  <em class="icon-i-pen"></em>
                </button>
              </div>
            </div>
          } @empty {
            <!-- TODO: Crear estilo global -->
            <div class="noDataToShowContainer">
              <em class="icon-i-add"></em><br><!-- TODO: Update icon by Daniela/Luis -->
              <span>No hay materias disponibles.<br>Comienza creando una nueva.</span>
            </div>
          }
        </div>
      }
    }
  </div>
</div>
<!-- MODALES -->
@if (MODALS_MANAGEMENT.show) {
  @switch (MODALS_MANAGEMENT.index) {
    @case (MODALS_MANAGEMENT.allOptions.AREA_MODAL) {
      <manage-area
        [isNewData]="MODALS_MANAGEMENT.isNewData"
        [receivedAreaData]="MODALS_MANAGEMENT.data.area"
        (eventCloseModal)="closeModals()"
        (eventUpdateDataAndCloseModal)="closeModalsAndUpdateData()"
      ></manage-area>
    }
    @case (MODALS_MANAGEMENT.allOptions.SUBJECT_MODAL) {
      <manage-subjects
        [isNewData]="MODALS_MANAGEMENT.isNewData"
        [receivedSubjectData]="MODALS_MANAGEMENT.data.Subject"
        (eventCloseModal)="closeModals()"
        (eventUpdateDataAndCloseModal)="closeModalsAndUpdateData()"
      ></manage-subjects>
    }
  }
}
