<div class="mainContainerCourseSection">
  <div class="container_header">
    <div class="container_headerTittle">
      <div class="containerData_courseName">
        <span>{{ courseOfferDataToShow.courseOffer.name }}</span>
      </div>
      <!-- /* TODO: dinamic css with state data */ -->
      <div class="containerData_courseState">
        <span>{{ courseOfferDataToShow.courseOffer.state }}</span>
      </div>
    </div>
    <div class="container_headerBtnClose">
      <button class="btnCallToAction" (click)="closeDetails()">X</button>
    </div>
  </div>
  <div class="container_data">
    <div class="containerData_tabsContainer">
      <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': viewTabIndex == 1 }" (click)="viewTabIndex=1">Detalles de la oferta</button>
      <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': viewTabIndex == 2 }" (click)="viewTabIndex=2">Notas</button>
      <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': viewTabIndex == 3 }" (click)="viewTabIndex=3">Materias</button>
    </div>
    @switch (viewTabIndex) {
      @case (1) {
        <div class="containerData_courseBtns">
          <div class="infoDataContainer">
            <div class="infoData01">
              <span><strong>Alumnos inscritos:</strong> {{ courseOfferDataToShow.courseOffer.registered }}</span>
            </div>
            <div class="infoData02">
              <span><strong>Alumnos aceptados:</strong> {{ courseOfferDataToShow.courseOffer.registered }}</span>
            </div>
          </div>
          <button class="btnCallToAction" (click)="showOfferModal()">Editar oferta</button>
        </div>
        <div class="containerData_courseData">
          <div class="courseData_infoSection">
            <strong>{{ textsToShow.schoolTittle }}: </strong><br><span>{{ courseOfferDataToShow.school.name }}</span>
          </div>
          <div class="courseData_infoSection">
            <strong>Categoría: </strong><br><span>{{ courseOfferDataToShow.courseOffer.category }}</span>
          </div>
          <div class="courseData_infoSection">
            <strong>Tipo: </strong><br><span>{{ courseOfferDataToShow.courseOffer.type }}</span>
          </div>
        </div>
        <div class="containerData_courseData">
          <div class="courseData_infoSection">
            <strong>Nombre del programa: </strong><br><span>{{ courseOfferDataToShow.course!.name }}</span>
          </div>
          <div class="courseData_infoSection">
            <strong>Estado del programa: </strong><br><span>{{ courseOfferDataToShow.course!.state }}</span>
          </div>
          <div class="courseData_infoSection">
            <strong>Entrenamiento: </strong><br><span>{{ courseOfferDataToShow.courseOffer.training }}</span>
          </div>
        </div>
        <div class="containerData_courseData">
          <div class="courseData_infoSection_v2">
            <strong>Nombre de la oferta: </strong><br><span>{{ courseOfferDataToShow.courseOffer.name }}</span>
          </div>
          <div class="courseData_infoSection_v2">
            <strong>Cupos: </strong><br><span>{{ courseOfferDataToShow.courseOffer.quotas }}</span>
          </div>
          <div class="courseData_infoSection_v2">
            <strong>Modalidad: </strong><br><span>{{ courseOfferDataToShow.courseOffer.modality }}</span>
          </div>
          <div class="courseData_infoSection_v2">
            <strong>Estado de la oferta: </strong><br><span>{{ courseOfferDataToShow.courseOffer.state }}</span>
          </div>
        </div>
        <div class="containerData_courseData">
          <div class="courseData_infoSection_v2">
            <strong>Fecha inicio: </strong><br><span>{{ courseOfferDataToShow.courseOffer.startDate }}</span>
          </div>
          <div class="courseData_infoSection_v2">
            <strong>Fecha final: </strong><br><span>{{ courseOfferDataToShow.courseOffer.finalDate }}</span>
          </div>
          <div class="courseData_infoSection_v2">
            <strong>Inicio inscripciones: </strong><br><span>{{ courseOfferDataToShow.courseOffer.startInscriptionDate }}</span>
          </div>
          <div class="courseData_infoSection_v2">
            <strong>Termino de inscripciones: </strong><br><span>{{ courseOfferDataToShow.courseOffer.finalInscriptionDate }}</span>
          </div>
        </div>
      }
      @case (2) {
        @for (item of [1, 2, 3, 4]; track $index) {
          <!-- TODO: preguntar a Luis de donde salen estos datos -->
          <offer-section-grades></offer-section-grades>
        }
      }
      @case (3) {
        @for (subjectOfferIterated of selectedCourseOfferData.SubjectV2Offers; track subjectOfferIterated.id) {
          <offer-details-subjects-offer
            [subjectOfferData]="subjectOfferIterated"
          ></offer-details-subjects-offer>
        } @empty {
          No hay materias para esta oferta
        }
      }
    }
  </div>
</div>
