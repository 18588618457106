import { AcademicaArea } from "./AcademicaArea";

export class SubjectV2 {
  id?: number;
  academicAreaID?: number;
  nameSubject?: string;
  subjectV2ID_Requirement?: number;
  HPM?: number; // HORAS DE TRABAJO PRESENCIAL
  HFH?: number; // HORAS DE TRABAJO CON ACOMPAÑAMIENTO
  HTI?: number; // Horas de trabajo Independiente
  description?: string;
  eduSup_semesterNumber?: number;
  maxSlots?: number;
  creditos?: number;
  typeRecord?: string;
  desiredGrade?: number;
  typeCreditID?: number;
  isGradeByTask?: boolean;

  /** ********* */
  /** RELATIONS */
  /** ********* */
  AcademicaArea?: AcademicaArea;

}
