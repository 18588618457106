<div class="subjectCourseDetailsMainContainer">
  <div class="sectionDescription">
    <div class="descriptionSection_left">
      <span class="descriptionTittle">Nombre de la materia</span><br>
      <span>{{ subjectDataToShow.name }}</span>
    </div>
    <div class="descriptionSection_center">
      <span class="descriptionTittle">Descripción de la materia</span><br>
      <span>{{ subjectDataToShow.description }}</span>
    </div>
    <div class="descriptionSection_right">
      <!-- <em class="icon-i-pencil"></em> -->
    </div>
  </div>
  <div class="sectionSubjects">
    <standard-subject-offer-details
      [subjectOfferData]="subjectOfferData"
    ></standard-subject-offer-details>
  </div>
</div>
