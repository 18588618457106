import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AcademicaArea } from '@models/db/AcademicaArea';
import { SweetalertService } from '@services/sweetalert.service';
import { environment } from 'src/environments/environment';
import { UiInputComponent } from "../../../ui/ui-input/ui-input.component";
import { FormsModule } from '@angular/forms';
import { UiSelectComponent } from "../../../ui/ui-select/ui-select.component";
import { UiSelectContentComponent } from "../../../ui/ui-select/ui-select-content/ui-select-content.component";
import { UiSelectItemComponent } from "../../../ui/ui-select/ui-select-item/ui-select-item.component";
import { UiTextareaComponent } from "../../../ui/ui-textarea/ui-textarea.component";
import { School } from '@models/db/school';
import { ApiService } from '@services/api-service.service';
import { forkJoin, take } from 'rxjs';

@Component({
  selector: 'manage-area',
  standalone: true,
  imports: [
    // Modulos de Jhan
    UiInputComponent,
    UiSelectComponent,
    UiSelectContentComponent,
    UiSelectItemComponent,
    UiTextareaComponent,
    // Angular modulos
    FormsModule,
],
  templateUrl: './manage-area.component.html',
  styleUrl: './manage-area.component.css'
})
export class ManageAreaComponent {

  @Input() isNewData!: boolean;
  @Input() receivedAreaData!: AcademicaArea;
  @Output() eventCloseModal = new EventEmitter<void>();
  @Output() eventUpdateDataAndCloseModal = new EventEmitter<void>();

  protected ALL_SCHOOL_LIST!: School[];

  protected localAreaData: AcademicaArea = {
    NameArea: '',
    SchoolID: 0,
    Description: '',
  };
  protected DATA_IN_HTML = {
    tittle: '',
    schoolSelect: '',
    btnText: '',
  };

  constructor (
    private _apiService: ApiService,
    private _sweetalertService: SweetalertService,
  ) {
    this.ALL_SCHOOL_LIST = this._apiService.ALL_SCHOOLS();
  };

  ngOnInit(): void {
    this.DATA_IN_HTML = {
      tittle: ( this.isNewData ? 'Crear' : 'Editar' ),
      schoolSelect: `Seleccionar ${environment.APP_DATA.SCHOOL_TEXT}`,
      btnText: ( this.isNewData ? 'Crear' : 'Actualizar' ),
    };
    if (this.isNewData) {
      this.localAreaData = {
        NameArea: '',
        SchoolID: 0,
        Description: '',
      };
    } else if (this.receivedAreaData) {
      this.localAreaData = this.receivedAreaData;
    } else this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{ this.closeModal() }); // Flujo EDITAR programa con ERROR al recibir el programa para editar
  }

  /* ****************
   * OUTPUT FUNCTIONS
   * **************** */

  protected closeModal(): void {
    this.eventCloseModal.emit();
  }

  protected updateDataAndCloseModal(): void {
    this.eventUpdateDataAndCloseModal.emit();
  }

  protected confirmProcessToValidateData(): void {
    this._sweetalertService.swalQuestion(
      'Confirmar acción',
      `¿Esta seguro de guardar esta área?`,
      () => {
        this.validateDataBeforeSendingReq()
      }
    );
  }

  private validateDataBeforeSendingReq(): void {
    let dataValidated = {
      isOk: true,
      msg: ''
    };
    if (
      this.localAreaData.NameArea?.trim() == '' ||
      this.localAreaData.NameArea!.trim().length < 4
    ) {
      dataValidated.isOk = false;
      dataValidated.msg += 'Nombre invalido. ';
    }
    if ( this.localAreaData?.SchoolID! <= 0 ) {
      dataValidated.isOk = false;
      dataValidated.msg += `Seleccione ${environment.APP_DATA.SCHOOL_TEXT}. `;
    }
    if (
      this.localAreaData.Description?.trim() == '' ||
      this.localAreaData.Description!.trim().length < 4
    ) {
      dataValidated.isOk = false;
      dataValidated.msg += 'Descripción invalida. ';
    }
    
    if (!dataValidated.isOk) {
      this._sweetalertService.swalError('Error', dataValidated.msg, ()=>{});
      return;
    }
    if (this.isNewData) this.saveData();
    else this.updateData();
  }

  private saveData(): void {
    const dataToCreate = {
      path: `AcademicaAreas`,
      data: this.localAreaData
    };

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      NEW_AREA_00: this._apiService.post( dataToCreate ).pipe( take( 1 ), ),
    }).subscribe({
      next: (responseCreate) => {
        console.log('responseCreate: ', responseCreate);
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han guardado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("createNewCourse error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });
  }

  private updateData(): void {
    const dataToUpdate = {
      path: `AcademicaAreas/${this.receivedAreaData.id!}`,
      data: this.localAreaData
    };

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      UPDATE_AREA_00: this._apiService.patch( dataToUpdate ).pipe( take(1) ),
    }).subscribe({
      next: (responseCreate) => {
        console.log('responseCreate: ', responseCreate);
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han guardado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("createNewCourse error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });

  }

}
