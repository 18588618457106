import { AcademicaArea } from "@models/db/AcademicaArea"
import { School } from "@models/db/school"
import { SubjectV2 } from "@models/db/SubjectV2"

/** TODO: descript data */
export interface AreaSubjectsPageFilter {
  ALL_OPTION: {
    SCHOOLS: School[],
    AREAS: AcademicaArea[]
  },
  SELECTED: {
    SCHOOL: number,
    AREA: number,
    TEXT: string,
  },
}
/** TODO: descript data */
export interface AreaSubjectsPageDataToShow {
  ALL_OPTION: {
    SCHOOLS: School[],
  },
  SELECTED: {
    SCHOOL: School[],
    AREA: AcademicaArea[],
    SUBJECTS: SubjectV2[],
  },
}

/** TODO: validar */
export enum enumTypeModalIndexAreaSubjectPage {
  AREA_MODAL,
  SUBJECT_MODAL,
}
