import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  computed,
  forwardRef,
  inject,
  input,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-textarea',
  templateUrl: './ui-textarea.component.html',
  styleUrl: './ui-textarea.component.css',
  standalone: true,
  imports: [CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiTextareaComponent),
      multi: true,
    },
  ],
})
export class UiTextareaComponent implements ControlValueAccessor {
  private _ref = inject(ElementRef);
  label = input<string>('');
  rows = input<number>(4);
  placeholder = input<string>();
  invalid = input<boolean>();
  disabled = input<boolean>();
  value = input<string | number>();
  variant = input<'flat' | 'bordered' | 'underline'>('flat');
  public clickedInside = signal<boolean>(false);
  private _controlValue = signal<string | number | undefined>(undefined);

  public finalValue = computed(() => this._controlValue() ?? this.value());

  private _activeInput = computed(() => this.clickedInside() || this.placeholder() || this.finalValue());
  public combinedClasses = computed(() => {
    const classes = {
      'textarea-field--active': this._activeInput() || this._ref.nativeElement.querySelector('.decorator')?.childNodes.length > 0,
      'disabled-input': this.disabled() || this.isDisabled(),
      'hidden-label': !this.label(),
      [this.variant()]:true,
      [`invalid-field invalid-field--${this.variant()}`]: this.invalid(),
      [`disabled disabled--${this.variant()}`]: this.disabled() || this.isDisabled()
    };
    return classes;
  });


  //#region ACCESSORS
  private _onChange = signal<(_?: any) => void>((_) => { });
  public onTouch = signal<() => void>(() => { });
  public isDisabled = signal<boolean>(false);
  //#endregion

  public onChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    
    //Get current input value
    this._controlValue.set(value);

    this._onChange.update((_) => (_(value), _));
  }

  //#region ACCESSORS
  writeValue(value: string | number): void {
    this._controlValue.set(value);
  }

  registerOnChange(fn: any): void {
    this._onChange.set(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouch.set(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }
  //#endregion

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {

    if(this.disabled() || this.isDisabled()) return;

    const target = event.target as HTMLElement;

    if (!this._ref.nativeElement.contains(target))
      this.clickedInside.set(false);
  }
}
