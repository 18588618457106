import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CourseSectionDetailsComponent } from '@components/mallas/course-section-details/course-section-details.component';
import { CourseSectionComponent } from '@components/mallas/course-section/course-section.component';
import { ManageAreaComponent } from '@components/mallas/modals/manage-area/manage-area.component';
import { ManageCourseComponent } from '@components/mallas/modals/manage-course/manage-course.component';
import { ManageOfferComponent } from '@components/mallas/modals/manage-offer/manage-offer.component';
import { ManageSubjectComponent } from '@components/mallas/modals/manage-subject/manage-subject.component';
import { OfferDetailsComponent } from '@components/mallas/offer-details/offer-details.component';
import { OfferSectionComponent } from '@components/mallas/offer-section/offer-section.component';
import { IDropdownItem } from '@components/ui/models/dropdown-item';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { UiSelectContentComponent } from '@components/ui/ui-select/ui-select-content/ui-select-content.component';
import { UiSelectItemComponent } from '@components/ui/ui-select/ui-select-item/ui-select-item.component';
import { UiSelectComponent } from '@components/ui/ui-select/ui-select.component';
import { Category } from '@models/db/category';
import { Course } from '@models/db/course';
import { CourseOffer } from '@models/db/courseOffer';
import { School } from '@models/db/school';
import { TypeCourse } from '@models/db/typeCourse';
import { TypeCourseDuration } from '@models/db/TypeCourseDuration';
import { IEndpoint } from '@models/endpoint';
import { ApiService } from '@services/api-service.service';
import { SweetalertService } from '@services/sweetalert.service';
import { forkJoin, map, take } from 'rxjs';
import { HasCapabilityDirective } from 'src/app/shared/directives/has-capabilities';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'page-mallas',
  standalone: true,
  imports: [
    // JhanComponents
    UiSelectComponent,
    UiSelectContentComponent,
    UiSelectItemComponent,
    UiInputComponent,
    HasCapabilityDirective,
    // AngularComponents
    CommonModule,
    // PlatfomrComponents
    CourseSectionComponent,
    OfferSectionComponent,
    CourseSectionDetailsComponent,
    OfferDetailsComponent,
    // Modals
    ManageOfferComponent,
    ManageCourseComponent,
    ManageAreaComponent,
    ManageSubjectComponent,
    // Fin
  ],
  templateUrl: './mallas-page.component.html',
  styleUrl: './mallas-page.component.css'
})
export class MallasPageComponent {

  public _enumTypeViewMallasPage = enumTypeViewMallasPage;
  public _enumTypeModalIndexMallasPage = enumTypeModalIndexMallasPage;

  public viewIndex: number = this._enumTypeViewMallasPage.COURSE_LIST;
  
  protected modalsManagement = {
    show: false,
    index: this._enumTypeModalIndexMallasPage.COURSE_MODAL,
    isNewData: false,
    areaID: 0,
    subjectID: 0,
    courseID: 0,
    offerID: 0,
  };

  protected filtersData: FiltersData = {
    selectOptions: {
      schools: this._apiService.schools(),
      categories: [],
      allTypeCourses: [],
      typeCoursesToShow: [],
    },
    dataSelected: {
      school: -1,
      category: -1,
      typeCourse: -1,
      inputText: '',
    }
  };

  protected MANAGE_COURSES: ManageAllCourseData = {
    ALL_LIST: [],
    SELECTED: undefined,
    TO_SHOW: [],
  };
  protected MANAGE_COURSE_OFFERS: ManageAllCourseOfferData = {
    ALL_LIST: [],
    SELECTED: undefined,
    TO_SHOW: [],
  };
  protected textsToShow = {
    schoolFilterTittle: 'Filtro por __',
  };

  constructor (
    private _apiService: ApiService,
    private _sweetalertService: SweetalertService,
  ) {
    this.textsToShow = {
      schoolFilterTittle: `Filtro por ${environment.APP_DATA.SCHOOL_TEXT.toLowerCase()}`
    };
    this.getAllInitialDataFirstStep();
  };

  private getAllInitialDataFirstStep(): void {
    console.log('getAllInitialDataFirstStep: ');
    
    const dataToGetAllCourses = this.getFiltersToCourses();
    const dataToGetAllCourseOffers = this.getFiltersToCoursesOffer();
    const dataToGetAllCategories = { path: "/Categories", };
    const dataToGetAllTypesCourse = { path: "/TypeCourses", };
    const dataToGetAllSchools = this.getFiltersToSchools();
    const dataToGetAllTypeCourseDurations = { path: "/TypeCourseDurations", };
    const dataToGetAllTypeTraining = { path: "/TypeTrainings", };
    const dataToGetAllModalities = { path: "/Modalities", };

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      FJ_ALL_COURSES_LIST: this._apiService.get( dataToGetAllCourses ).pipe(
        take(1),
        map((courses: any[]) => courses.map(courseData => new Course(courseData) ) )
      ),
      FJ_ALL_COURSE_OFFERS_LIST: this._apiService.get( dataToGetAllCourseOffers ).pipe(
        take(1),
        map((courses: any[]) => courses.map(courseData => new CourseOffer(courseData) ) )
      ),
      FJ_ALL_CATEGORY_LIST: this._apiService.get<Category>( dataToGetAllCategories ).pipe( take(1) ),
      FJ_ALL_TYPES_COURSE_LIST: this._apiService.get<TypeCourse>( dataToGetAllTypesCourse ).pipe( take(1) ),
      FJ_ALL_SCHOOLS_LIST: this._apiService.get<School>( dataToGetAllSchools ).pipe( take(1) ),
      FJ_ALL_TYPE_COURSES_DURATION_LIST: this._apiService.get<TypeCourseDuration>( dataToGetAllTypeCourseDurations ).pipe( take(1) ),
      FJ_ALL_TYPE_TRAINING_LIST: this._apiService.get<TypeCourseDuration>( dataToGetAllTypeTraining ).pipe( take(1) ),
      FJ_ALL_MODALITIES_LIST: this._apiService.get<TypeCourseDuration>( dataToGetAllModalities ).pipe( take(1) ),
    }).subscribe({
      next: (forkjoinResponse) => {
        console.log("getAllInitialDataFirstStep response:", forkjoinResponse);
        let { FJ_ALL_COURSES_LIST, FJ_ALL_COURSE_OFFERS_LIST, FJ_ALL_CATEGORY_LIST, FJ_ALL_TYPES_COURSE_LIST, FJ_ALL_SCHOOLS_LIST, FJ_ALL_TYPE_COURSES_DURATION_LIST, FJ_ALL_TYPE_TRAINING_LIST, FJ_ALL_MODALITIES_LIST } = forkjoinResponse;
        /** Guardar todos los Courses */
        // TODO: Organizar manuealmente los resultados de FJ_ALL_COURSES_LIST.AcademicAreasCourse
        this.MANAGE_COURSES.ALL_LIST = FJ_ALL_COURSES_LIST;
        this.MANAGE_COURSES.TO_SHOW = FJ_ALL_COURSES_LIST;
        /** Guardar todos los CourseOffers */
        this.MANAGE_COURSE_OFFERS.ALL_LIST = FJ_ALL_COURSE_OFFERS_LIST;
        this.MANAGE_COURSE_OFFERS.TO_SHOW = FJ_ALL_COURSE_OFFERS_LIST;
        /** Guardar todos los datos para los filtros */
        this.filtersData.selectOptions.categories = FJ_ALL_CATEGORY_LIST;
        this.filtersData.selectOptions.allTypeCourses = FJ_ALL_TYPES_COURSE_LIST;
        /** Guardar todos los datos de las escuelas */
        this.filtersData.selectOptions.schools = FJ_ALL_SCHOOLS_LIST;
        /** Guardar todos los datos para las modales en el servicio (Signals) */
        this._apiService.ALL_CATEGORIES_LIST.set(FJ_ALL_CATEGORY_LIST);
        this._apiService.ALL_TYPE_COURSES_DURATION.set(FJ_ALL_TYPE_COURSES_DURATION_LIST);
        this._apiService.ALL_TYPE_COURSES.set(FJ_ALL_TYPES_COURSE_LIST);
        this._apiService.ALL_TYPE_TRAINING.set(FJ_ALL_TYPE_TRAINING_LIST);
        this._apiService.ALL_MODALITIES.set(FJ_ALL_MODALITIES_LIST);
        /** Cerrar la modal */
        this._sweetalertService.swalClose();
      },
      error: (err) => {
        console.log("getAllInitialDataFirstStep error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      },
      complete: () => {
        console.log("getAllInitialDataFirstStep complete:");
      }
    });
  }

  private getAllUploadedData(): void {
    console.log('getAllUploadedData: ');

    const dataToGetAllCourses = this.getFiltersToCourses();
    const dataToGetAllCourseOffers = this.getFiltersToCoursesOffer();

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      FJ_ALL_COURSES_LIST: this._apiService.get( dataToGetAllCourses ).pipe(
        take(1),
        map((courses: any[]) => courses.map(courseData => new Course(courseData) ) )
      ),
      FJ_ALL_COURSE_OFFERS_LIST: this._apiService.get( dataToGetAllCourseOffers ).pipe(
        take(1),
        map((courses: any[]) => courses.map(courseData => new CourseOffer(courseData) ) )
      ),
    }).subscribe({
      next: (forkjoinResponse) => {
        console.log("getAllUploadedData response:", forkjoinResponse);
        let { FJ_ALL_COURSES_LIST, FJ_ALL_COURSE_OFFERS_LIST } = forkjoinResponse;
        /** Guardar todos los Courses */
        // TODO: Organizar manuealmente los resultados de FJ_ALL_COURSES_LIST.AcademicAreasCourse
        this.MANAGE_COURSES.ALL_LIST = FJ_ALL_COURSES_LIST;
        this.MANAGE_COURSES.TO_SHOW = FJ_ALL_COURSES_LIST;
        /** Guardar todos los CourseOffers */
        this.MANAGE_COURSE_OFFERS.ALL_LIST = FJ_ALL_COURSE_OFFERS_LIST;
        this.MANAGE_COURSE_OFFERS.TO_SHOW = FJ_ALL_COURSE_OFFERS_LIST;
        /** Guardar la información del curso seleccionado */
        if (this.MANAGE_COURSES.SELECTED) this.MANAGE_COURSES.SELECTED = FJ_ALL_COURSES_LIST.find( course => course.id == this.MANAGE_COURSES.SELECTED?.id );
        /** Cerrar la modal */
        this._sweetalertService.swalClose();
      },
      error: (err) => {
        console.log("getAllUploadedData error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      },
      complete: () => {
        console.log("getAllUploadedData complete:");
      }
    });
  }

  /** *************************************************** */
  /** Functions to get properties to get all Data from DB */
  /** *************************************************** */

  private getFiltersToCourses(): IEndpoint {
    return {
      path: "/Courses",
      filter: {
        order: 'NameCourse ASC',
        where: { and: [
          { SchoolID: { inq: this._apiService.schools().map( item => item.id ) } },
          { version: 2 },
        ] },
        include: [
          { School: {
            relation: "AcademicAreas",
            scope: {
              order: 'NameArea ASC',
              include: [ {
                relation: 'Subjects',
                scope: {
                  order: [ 'NameSubject ASC', 'SemesterNumber ASC'],
                  include: [ 'AcademicaArea' ]
                }
              } ]
            }
          } },
          {
            relation: 'Subjects',
            scope: {
              order: [ 'NameSubject ASC', 'SemesterNumber ASC'],
              include: [ 'AcademicaArea' ]
            }
          },
          { TypeCourse: 'Category' },
          { AcademicAreasCourse: [ 'AcademicArea', { SubjectsV2: 'AcademicPerformances' } ] },
          {
            relation: 'SubjectsV2',
            scope: {
              order: [ 'nameSubject ASC'],
              include: [ 'AcademicPerformances' ]
            }
          },
          'TypeCourseDuration'
        ]
      }
    };
  }

  private getFiltersToCoursesOffer(): IEndpoint {
    return {
      path: "/CoursesOfers",
      filter: {
        order: 'NameCourseOfer ASC',
        include: [
          {
            include: [ 'AcademicPerformancesOffer' ],
            relation: 'SubjectV2Offers',
            scope: { order: [ 'nameSubject ASC'], }
          },
          'school',
          'oferState',
          'TypeCourseOFer',
          { course: { Subjects: 'AcademicaArea' } },  /** TODO: Revisar si se usan estas relaciones */
          {'AcademicaAreaOffers': { 'SubjectV2Offers': 'AcademicPerformancesOffer' } }
        ],
        where: { and: [
          { SchoolID: { inq: this._apiService.schools().map( item => item.id ) } },
          { version: 2 },
        ] },


      }
    };
  }

  private getFiltersToSchools(): IEndpoint {
    return {
      path: "/Schools",
      filter: {
        order: 'NameTSchool ASC',
        where: { SchoolID: { inq: this._apiService.schools().map( item => item.id ) } },
        include: [
          'Courses',
          { 
            relation: "AcademicAreas",
            scope: {
              order: 'NameArea ASC',
              include: [
                {
                  relation: 'SubjectsV2',
                  scope: {
                    order: 'nameSubject ASC',
                    include: [ 'AcademicaArea' ]
                  }
                }
              ]
            }
          },
        ]
      }
    };
  }

  /** *************************************************** */

  protected courseSelectedFromList(courseSelected: Course): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    console.log('courseSelected', courseSelected);
    this.MANAGE_COURSES.SELECTED = courseSelected;
    this.viewIndex = this._enumTypeViewMallasPage.COURSE_DETAILS;
    this._sweetalertService.swalClose();
  }

  protected courseOfferSelectedFromList(courseOfferSelected: CourseOffer): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    console.log('courseOfferSelected', courseOfferSelected);
    this.MANAGE_COURSE_OFFERS.SELECTED = courseOfferSelected;

    let indexCourseOfferFinded = this.MANAGE_COURSES.ALL_LIST.findIndex( x => x.id == courseOfferSelected.CourseID);
    console.log('CourseOfferFinded: ', indexCourseOfferFinded);
    console.log('CourseOfferFinded: ', this.MANAGE_COURSES.ALL_LIST[indexCourseOfferFinded]);
    if (indexCourseOfferFinded == -1) {
      this._sweetalertService.swalError('Error', 'El programa base de esta oferta no se encontro, contacte a soporte.', ()=>{});
      return;
    }
    this.MANAGE_COURSES.SELECTED = this.MANAGE_COURSES.ALL_LIST[indexCourseOfferFinded];

    this.viewIndex = this._enumTypeViewMallasPage.COURSE_OFFER_DETAILS;
    this._sweetalertService.swalClose();
  }

  protected closeDetails(viewToShow: number): void {
    this.viewIndex = viewToShow;
  }

  protected manageModalsToShow(modalToShow: number): void {
    this.modalsManagement.index = modalToShow;
    this.modalsManagement.show = true;
  }

  protected closeModals(): void {
    this.modalsManagement.show = false;
  }

  protected updateDataAndCloseModal(): void {
    this.getAllUploadedData();
    this.modalsManagement.show = false;
  }

  onSchoolSelectChange(optionSelected: IDropdownItem): void {
    this.filtersData.dataSelected.school = Number(optionSelected.value);
    this.applyFilters();
  }

  onCategorySelectChange(optionSelected: IDropdownItem): void {
    this.filtersData.dataSelected.category = Number(optionSelected.value);
    this.filtersData.selectOptions.typeCoursesToShow = this.filtersData.selectOptions.allTypeCourses.filter(x => x.CategoryID == Number(optionSelected.value));
    this.applyFilters();
  }

  onTypeCourseSelectChange(optionSelected: IDropdownItem): void {
    this.filtersData.dataSelected.typeCourse = Number(optionSelected.value);
    this.applyFilters();
  }

  onStringQuerySelectChange(optionSelected: any): void {
    console.log('AAAAAAAAAAAAAAAAAAAA', optionSelected);
    console.log('AAAAAAAAAAAAAAAAAAAA', optionSelected.target.value);
    this.filtersData.dataSelected.inputText = optionSelected.target.value;
    this.applyFilters();
  }

  applyFilters(): void {
    this.MANAGE_COURSES.TO_SHOW = this.MANAGE_COURSES.ALL_LIST.filter(courseIterated => (
      (
        this.filtersData.dataSelected.school == -1 ||
        this.filtersData.dataSelected.school == courseIterated.SchoolID
      ) &&
      (
        this.filtersData.dataSelected.category == -1 ||
        this.filtersData.dataSelected.category == courseIterated.TypeCourse?.CategoryID
      ) &&
      (
        this.filtersData.dataSelected.typeCourse == -1 ||
        this.filtersData.dataSelected.typeCourse == courseIterated.TypeCourseID
      ) &&
      (
        this.filtersData.dataSelected.inputText.trim() == '' ||
        courseIterated.NameCourse!.toLowerCase().includes(this.filtersData.dataSelected.inputText.trim().toLowerCase())
      )
    ));
    this.MANAGE_COURSE_OFFERS.TO_SHOW = this.MANAGE_COURSE_OFFERS.ALL_LIST.filter(courseOfferIterated => (
      (
        this.filtersData.dataSelected.school == -1 ||
        this.filtersData.dataSelected.school == courseOfferIterated.SchoolID
      ) &&
      (
        this.filtersData.dataSelected.category == -1 ||
        this.filtersData.dataSelected.category == courseOfferIterated.TypeCourseOFer?.CategoryID
      ) &&
      (
        this.filtersData.dataSelected.typeCourse == -1 ||
        this.filtersData.dataSelected.typeCourse == courseOfferIterated.TypeCourseID
      ) &&
      (
        this.filtersData.dataSelected.inputText.trim() == '' ||
        courseOfferIterated.NameCourseOfer!.toLowerCase().includes(this.filtersData.dataSelected.inputText.trim().toLowerCase())
      )
    ));
    switch (this.viewIndex) {
      case this._enumTypeViewMallasPage.COURSE_LIST:
        if (this.MANAGE_COURSES.TO_SHOW.length == 0) this._sweetalertService.swalError('', 'No hay datos para mostrar', ()=>{});
        break;
      case this._enumTypeViewMallasPage.COURSE_OFFER_LIST:
        if (this.MANAGE_COURSE_OFFERS.TO_SHOW.length == 0) this._sweetalertService.swalError('', 'No hay datos para mostrar', ()=>{});
        break;
    }
  }

  protected showAreaModal(data: {isNew: boolean, index: number} ): void {
    console.log('showAreaModal: ', data);
    this.modalsManagement.isNewData = data.isNew;
    this.modalsManagement.areaID = data.index;
    this.manageModalsToShow(this._enumTypeModalIndexMallasPage.AREA_MODAL);
  }

  protected showSubjectModal(data: {isNew: boolean, index: number} ): void {
    console.log('showSubjectModal: ', data);
    this.modalsManagement.isNewData = data.isNew;
    this.modalsManagement.subjectID = data.index;
    this.manageModalsToShow(this._enumTypeModalIndexMallasPage.SUBJECT_MODAL);
  }

  protected showOfferModal(data: {isNew: boolean, index: number} ): void {
    console.log('showOfferModal: ', data);
    this.modalsManagement.isNewData = data.isNew;
    if (data.isNew) this.modalsManagement.courseID = data.index;
    else this.modalsManagement.offerID = data.index;
    this.manageModalsToShow(this._enumTypeModalIndexMallasPage.COURSE_OFFER_MODAL);
  }

  protected showCourseModal(data: {isNew: boolean, index: number} ): void {
    console.log('showCourseModal: ', data);
    /** TODO: Validar donde se selecciona la escuela */
    // if ( this.filtersData.dataSelected.school <= 0 ) {
    //   this._sweetalertService.swalError('Error', 'En la sección de filtros se debe seleccionar la escuela en la cual se va a crear el nuevo programa.', ()=>{});
    //   return;
    // }
    this.modalsManagement.isNewData = data.isNew;
    this.modalsManagement.courseID = data.index;
    this.manageModalsToShow(this._enumTypeModalIndexMallasPage.COURSE_MODAL);
  }

}

/** TODO: move to a new file */
enum enumTypeViewMallasPage {
  COURSE_LIST,
  COURSE_OFFER_LIST,
  COURSE_DETAILS,
  COURSE_OFFER_DETAILS,
}

/** TODO: move to a new file */
enum enumTypeModalIndexMallasPage {
  COURSE_MODAL,
  COURSE_OFFER_MODAL,
  SUBJECT_MODAL,
  AREA_MODAL,
}

/** TODO: move to a new file */
interface FiltersData {
  selectOptions: {
    schools: School[],
    categories: Category[],
    allTypeCourses: TypeCourse[],
    typeCoursesToShow: TypeCourse[],
  };
  dataSelected: {
    school: number,
    category: number,
    typeCourse: number,
    inputText: string,
  };
}

/** TODO: move to a new file */
interface ManageAllCourseData {
  ALL_LIST: Course[],
  SELECTED?: Course,
  TO_SHOW?: Course[],
}
interface ManageAllCourseOfferData {
  ALL_LIST: CourseOffer[],
  SELECTED?: CourseOffer,
  TO_SHOW?: CourseOffer[],
}
