<div class="subjectCourseDetailsMainContainer">
  <div class="sectionDescription">
    <div class="descriptionSection_left">
      <span class="descriptionTittle">Nombre de la materia</span><br>
      <span>{{ subjectDataToShow.name }}</span>
    </div>
    <div class="descriptionSection_center">
      <span class="descriptionTittle">Descripción de la materia</span><br>
      <span>{{ subjectDataToShow.description }}</span>
    </div>
    <div class="descriptionSection_right">
      <!-- <button class="invisibleBtn" (click)="showSubjectModal()">
        <em class="icon-i-pencil editIcon"></em>
      </button> -->
    </div>
  </div>
  <div class="sectionSubjects">
    <standard-subject-v2-course-details
      [subjectData]="subjectData"
      [areaData]="subjectData.AcademicAreaCourse!"
      [teachingHoursMin]="10"
    ></standard-subject-v2-course-details><!-- TODO: teachingHoursMin -->
  </div>
</div>
