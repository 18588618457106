export const environment = {
  production: false,
  URL_BACK_V1: 'https://test-user-dot-cedoc-360c.ue.r.appspot.com/api/',
  URL_BACK_V2: 'https://test-default-dot-cedoc-360c.ue.r.appspot.com/api/',
  APP_NAME: 'cedoc',
  APP_DATA: {
    NAME: 'cedoc',
    SCHOOL_TEXT: 'Escuela'
  }
};
