@if (false) {
  <button *hasCapability="{canCreate:true}; else testing" class="btnNavigateSections">PRUEBA CAPACIDADES</button>
  <ng-template #testing>
    Esto es una prueba
  </ng-template>
}
<div class="mainContainerMallasPage">
  <!-- BOTONES SUPERIORES -->
  <div class="mainContainerMallasPage_upperBtns">
    <div class="upperBtns_left">
      <button class="btnNavigateSections" [ngClass]="{ 'btnNavigateSections_selected': viewIndex == _enumTypeViewMallasPage.COURSE_LIST }" (click)="viewIndex = _enumTypeViewMallasPage.COURSE_LIST">PROGRAMAS</button>
      <button class="btnNavigateSections" [ngClass]="{ 'btnNavigateSections_selected': viewIndex == _enumTypeViewMallasPage.COURSE_OFFER_LIST }" (click)="viewIndex = _enumTypeViewMallasPage.COURSE_OFFER_LIST">OFERTAS</button>
    </div>
    <div class="upperBtns_right">
      <button class="btnCallToAction" (click)="showCourseModal({isNew: true, index: 0})">Crear nuevo programa</button>
    </div>
  </div>
  <!-- FILTROS -->
  <div class="mainContainerMallasPage_filtersSection">
    <div>
      <ui-select [label]="textsToShow.schoolFilterTittle" defaultValue="0" (onValueChange)="onSchoolSelectChange($event)">
        <ui-select-content>
          <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
          <ui-select-item value="-1">Todos</ui-select-item>
          @for (schoolIterated of filtersData.selectOptions.schools; track schoolIterated.id) {
            <ui-select-item [value]="schoolIterated.id!">{{ schoolIterated.NameTSchool }}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
    </div>
    <div>
      <ui-select label="Filtro por tipo de programa" defaultValue="0" (onValueChange)="onCategorySelectChange($event)">
        <ui-select-content>
          <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
          <ui-select-item value="-1">Todos</ui-select-item>
          @for (categoryIterated of filtersData.selectOptions.categories; track categoryIterated.id) {
            <ui-select-item [value]="categoryIterated.id!">{{ categoryIterated.NameCategoryCourse }}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
    </div>
    <div>
      <ui-select label="Filtro por subtipo de programa" defaultValue="0" (onValueChange)="onTypeCourseSelectChange($event)">
        <ui-select-content>
          <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
          <ui-select-item value="-1">Todos</ui-select-item>
          @for (typeCourseIterated of filtersData.selectOptions.typeCoursesToShow; track typeCourseIterated.id) {
            <ui-select-item [value]="typeCourseIterated.id!">{{ typeCourseIterated.NameTypeCourse }}</ui-select-item>
          } @empty {
            <ui-select-item value="-2" [disabled]="true">Seleccione tipo de programa</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
    </div>
    <div>
      <ui-input
        variant="flat"
        label="Filtro por texto"
        placeholder="Escriba una palabra para filtrar por texto"
        (change)="onStringQuerySelectChange($event)"
      />
    </div>
  </div>
  <!-- CONTENIDO -->
  <div class="mainContainerMallasPage_resultsCards">
    @switch (viewIndex) {
      @case (_enumTypeViewMallasPage.COURSE_LIST) {
        @for (courseItem of MANAGE_COURSES.TO_SHOW; track courseItem.id) {
          <div class="showDataWithDefaultAnimation">
            <button (click)="courseSelectedFromList(courseItem)" class="invisibleBtn">
              <course-section
                [courseReceived]="courseItem"
              ></course-section>
            </button>
          </div>
        } @empty {
          <div class="noDataToShowContainer">
            <em class="icon-i-add"></em><br><!-- TODO: Update icon by Daniela -->
            <span>No hay programas disponibles.<br>Comienza creando un nuevo programa</span>
          </div>
        }
      }
      @case (_enumTypeViewMallasPage.COURSE_OFFER_LIST) {
        @for (courseOfferItem of MANAGE_COURSE_OFFERS.TO_SHOW; track courseOfferItem) {
          <button (click)="courseOfferSelectedFromList(courseOfferItem)" class="invisibleBtn">
            <offer-section
              [courseOfferReceived]="courseOfferItem"
            ></offer-section>
          </button>
        } @empty {
          <div class="noDataToShowContainer">
            <em class="icon-i-add"></em><br><!-- TODO: Update icon by Daniela -->
            <span>No hay ofertas disponibles.<br>Comienza creando una oferta desde<br>un programa existente.</span>
          </div>
        }
      }
      @case (_enumTypeViewMallasPage.COURSE_DETAILS) {
        <course-details
          [selectedCourseData]="MANAGE_COURSES.SELECTED!"
          (responseCloseDetails)="closeDetails(_enumTypeViewMallasPage.COURSE_LIST)"
          (responseShowAreaModal)="showAreaModal($event)"
          (responseShowSubjectModal)="showSubjectModal($event)"
          (responseShowOfferModal)="showOfferModal($event)"
          (responseShowCourseModal)="showCourseModal($event)"
        ></course-details>
      }
      @case (_enumTypeViewMallasPage.COURSE_OFFER_DETAILS) {
        <offer-details
          [selectedCourseOfferData]="MANAGE_COURSE_OFFERS.SELECTED!"
          (responseCloseDetails)="closeDetails(_enumTypeViewMallasPage.COURSE_OFFER_LIST)"
          (responseShowOfferModal)="showOfferModal($event)"
        ></offer-details>
      }
    }
  </div>
</div>

<!-- MODALES -->
@if (modalsManagement.show) {
  @switch (modalsManagement.index) {
    @case (_enumTypeModalIndexMallasPage.COURSE_MODAL) {
      <modal-manage-course
        [isNewData]="modalsManagement.isNewData"
        [courseData]="MANAGE_COURSES.SELECTED!"
        [allSchoolsListData]="filtersData.selectOptions.schools"
        [allTypeCoursesListData]="filtersData.selectOptions.allTypeCourses"
        (eventCloseModal)="closeModals()"
        (eventUpdateDataAndCloseModal)="updateDataAndCloseModal()"
      ></modal-manage-course>
    }
    @case (_enumTypeModalIndexMallasPage.COURSE_OFFER_MODAL) {
      <modal-manage-offer
        [isNewData]="modalsManagement.isNewData"
        [offerID]="modalsManagement.offerID"
        [offerData]="MANAGE_COURSE_OFFERS.SELECTED!"
        [courseData]="MANAGE_COURSES.SELECTED!"
        (eventCloseModal)="closeModals()"
        (eventUpdateDataAndCloseModal)="updateDataAndCloseModal()"
      ></modal-manage-offer>
    }
    @case (_enumTypeModalIndexMallasPage.AREA_MODAL) {
      <modal-manage-area
        [isNewData]="modalsManagement.isNewData"
        [areaID]="modalsManagement.areaID"
        [courseData]="MANAGE_COURSES.SELECTED!"
        (eventCloseModal)="closeModals()"
        (eventUpdateDataAndCloseModal)="updateDataAndCloseModal()"
      ></modal-manage-area>
    }
    @case (_enumTypeModalIndexMallasPage.SUBJECT_MODAL) {
      <modal-manage-subject
        [isNewData]="modalsManagement.isNewData"
        [subjectID]="modalsManagement.subjectID"
        [courseData]="MANAGE_COURSES.SELECTED!"
        (eventCloseModal)="closeModals()"
        (eventUpdateDataAndCloseModal)="updateDataAndCloseModal()"
      ></modal-manage-subject>
    }
  }
}
