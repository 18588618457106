import { AcademicaAreaCourse } from "./academicaAreaCourse";
import { AcademicPerformance } from "./AcademicPerformance";

export class SubjectV2Course {
  id?: number;
  nameSubject?: string;
  courseID?: number;
  subjectV2ID_Requirement?: number;
  HPM?: number; // HORAS DE TRABAJO PRESENCIAL
  HFH?: number; // HORAS DE TRABAJO CON ACOMPAÑAMIENTO
  HTI?: number; // Horas de trabajo Independiente
  description?: string;
  eduSup_semesterNumber?: number;
  maxSlots?: number;
  creditos?: number;
  typeRecord?: string;
  desiredGrade?: number;
  typeCreditID?: number;
  isGradeByTask?: boolean;
  AcademicaAreaCourseID?: number;
  subjectV2ID?: number;

  /** ********* */
  /** RELATIONS */
  /** ********* */
  AcademicAreaCourse?: AcademicaAreaCourse;
  AcademicPerformances?: AcademicPerformance[];

  constructor (data?: Partial<SubjectV2Course>) {
    Object.assign(this, data);
  }

  /** ******* */
  /** METHODS */
  /** ******* */

  public getSubjectType(): string {
    let allStringTypes = [
      'Sin información',
      'Puntos',
      'Porcentaje',
      'Créditos',
    ]
    let response = '';
    if ( this.typeCreditID && this.typeCreditID > 0 && this.typeCreditID <= allStringTypes.length ) response = allStringTypes[this.typeCreditID];
    else response = allStringTypes[0];
    return response;
  }

}
