<div class="modalMainContainer">
  <div class="myModal">
    <!-- HEADER -->
    <div class="myModal_header">
      <span>{{ DATA_IN_HTML.tittle }} área</span>
      <div>
        <button class="invisibleBtn" (click)="closeModal()">
          <div class="myModal_headerClose">
            <em class="icon-i-close"></em>
          </div>
        </button>
      </div>
    </div>
    <!-- BODY -->
    <div class="myModal_body">
      <div><!-- Input SCHOOL -->
        <ui-input
          variant="bordered"
          label="Nombre del área"
          placeholder="Nombre del área"
          [(ngModel)]="localAreaData.NameArea"
        />
      </div>
      <div><!-- Select SCHOOL -->
        <ui-select variant="bordered" [label]="DATA_IN_HTML.schoolSelect" [(ngModel)]="localAreaData.SchoolID">
          <ui-select-content>
            <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
            @for (schoolIterated of ALL_SCHOOL_LIST; track schoolIterated.id) {
              <ui-select-item [value]="schoolIterated.id!">{{ schoolIterated.NameTSchool }}</ui-select-item>
            }
          </ui-select-content>
        </ui-select>
      </div>
      <div><!-- Textarea Descripción -->
        <ui-textarea
          variant="bordered"
          label="Descripción"
          placeholder="Descripción"
          [(ngModel)]="localAreaData.Description"
        />
      </div>
    </div>
    <!-- FOOTER -->
    <div class="myModal_footer">
      <button (click)="confirmProcessToValidateData()">{{ DATA_IN_HTML.btnText }} área</button>
    </div>
  </div>
</div>
