<div class="mainContainerCourseSection">
  <div class="container_icon">
    <img [src]="courseDataToShow.school.url_img" alt="Logo">
  </div>
  <div class="container_data">
    <div class="containerData_schoolName">
      <span>{{ courseDataToShow.school.name }}</span>
    </div>
    <div class="containerData_courseName">
      <span>{{ courseDataToShow.course.name }}</span>
    </div>
    <div class="containerData_courseData">
      <div class="courseData_infoSection">
        <strong>Tipo de curso: </strong><span>{{ courseDataToShow.course.typeCourse }}</span>
      </div>
      @if (courseDataToShow.course.showRegMinE) {
        <div class="courseData_infoSection">
          <strong>Registro Calificado No: </strong><span>{{ courseDataToShow.course.regMinE }}</span>
        </div>
      }
      @if (courseDataToShow.course.showRegMil) {
        <div class="courseData_infoSection">
          <strong>Registro Militar No: </strong><span>{{ courseDataToShow.course.regMil }}</span>
        </div>
      }
      @if (courseDataToShow.course.showDuration) {
        <div class="courseData_infoSection">
          <strong>Duración: </strong><span>{{ courseDataToShow.course.duration }}</span>
        </div>
      }
      <button class="btnCallToAction">Crear oferta</button>
    </div>
    <div class="containerData_courseDescript">
      <strong><span class="headerSection">Descripción del programa:</span></strong><br>
      <span>{{ courseDataToShow.course.description }}</span>
    </div>
  </div>
</div>
