import { School } from "./school";
import { Subject } from "./Subject";
import { SubjectV2 } from "./SubjectV2";

export class AcademicaArea {
  id?: number;
  NameArea?: string;
  SchoolID?: number;
  Description?: string;

  /** ********* */
  /** RELATIONS */
  /** ********* */
  School?: School;
  Subjects?: Subject[];
  SubjectsV2?: SubjectV2[];

}
