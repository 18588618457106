import { CommonModule } from '@angular/common';
import { Component, computed, forwardRef, input, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-date-input',
  templateUrl: './ui-date-input.component.html',
  styleUrl: './ui-date-input.component.css',
  standalone: true,
  imports: [CommonModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiDateInputComponent),
      multi: true,
    },
  ],
})
export class UiDateInputComponent implements ControlValueAccessor {
  // Inputs
  label = input<string>();
  invalid = input<boolean>();
  disabled = input<boolean>();
  type = input<'date' | 'datetime-local'>('date');
  value = input<string | number>();
  variant = input<'flat' | 'bordered' | 'underline'>('flat');

  private _controlValue = signal<string | number | undefined>(undefined);
  public finalValue = computed(() => this._controlValue() !== undefined ? this._controlValue() : this.value());

  /**
   * `combinedClasses` is a computed property that generates the appropriate CSS classes based on the component's state,
   * including the label, variant, invalid, and disabled states.
   */
  public combinedClasses = computed(() => {
    const classes = {
      'no-label': !this.label(),
      [this.variant().toString()]:true,
      [`invalid-field invalid-field--${this.variant()}`]: this.invalid(),
      [`disabled disabled--${this.variant()}`]: this.disabled() || this.isDisabled()
    };
    return classes;
  });

  //#region ACCESSOR
  private _onChange = signal<(_: any) => void>(_ => { });
  public onTouch = signal<() => void>(() => { });
  public isDisabled = signal<boolean>(false);
  //#endregion

  /**
   * `onValueChange` is triggered when the input value changes.
   * It updates the internal `_controlValue` and invokes the registered change handler.
   */
  public onValueChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this._onChange.update((_) => ( _(value), _));
  }

  //#region ACCESSORS
  writeValue(value: string): void {
    this._controlValue.set(value);
  }

  registerOnChange(fn: any): void {
    this._onChange.set(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouch.set(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }
  //#endregion
}
