import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Course } from '@models/db/course';
import { CourseSectionDataToShow } from '@models/logicModels/mallas/dataCourseSection';
import { CommonModule } from '@angular/common';
import { CourseSectionAreaComponent } from '../course-section-area/course-section-area.component';
import { CourseSectionSubjectsComponent } from '../course-section-subjects/course-section-subjects.component';
import { UiSelectItemComponent } from '@components/ui/ui-select/ui-select-item/ui-select-item.component';
import { UiSelectContentComponent } from '@components/ui/ui-select/ui-select-content/ui-select-content.component';
import { UiSelectComponent } from '@components/ui/ui-select/ui-select.component';
import { IDropdownItem } from '@components/ui/models/dropdown-item';
import { UiInputComponent } from "../../ui/ui-input/ui-input.component";
import { SubjectV2 } from '@models/db/SubjectV2';
import { SweetalertService } from '@services/sweetalert.service';
import { SubjectV2Course } from '@models/db/SubjectV2Course';

@Component({
  selector: 'course-details',
  standalone: true,
  imports: [
    UiSelectComponent,
    UiSelectContentComponent,
    UiSelectItemComponent,
    CommonModule,
    CourseSectionAreaComponent,
    CourseSectionSubjectsComponent,
    UiInputComponent
],
  templateUrl: './course-section-details.component.html',
  styleUrl: './course-section-details.component.css'
})
export class CourseSectionDetailsComponent {

  @Input() selectedCourseData!: Course;
  @Output() responseCloseDetails = new EventEmitter<void>();
  @Output() responseShowAreaModal = new EventEmitter<{isNew: boolean, index: number}>();
  @Output() responseShowSubjectModal = new EventEmitter<{isNew: boolean, index: number}>();
  @Output() responseShowOfferModal = new EventEmitter<{isNew: boolean, index: number}>();
  @Output() responseShowCourseModal = new EventEmitter<{isNew: boolean, index: number}>();

  public courseDataToShow!: CourseSectionDataToShow;
  
  public TYPE_TAP_VIEW_ENUM = enumTypeTabViewCourseDetails;

  public viewTabIndex: number = this.TYPE_TAP_VIEW_ENUM.DETAILS;

  protected subjectsToShow: SubjectV2Course[] = [];
  protected filtersData: SubjectsV2FiltersData = {
    dataSelected: {
      area: -1,
      inputText: '',
    }
  };

  constructor(
    private readonly _sweetalertService: SweetalertService,
  ) { }

  ngOnInit(): void {
    if (this.selectedCourseData.SubjectsV2 && this.selectedCourseData.SubjectsV2.length > 0) this.subjectsToShow = this.selectedCourseData.SubjectsV2;
    this.courseDataToShow = {
      school: {
        name: this.selectedCourseData.getSchoolAcronimAndSchoolName(),
        url_img: this.selectedCourseData.getSchoolIconUrl(),
      },
      course: {
        name: this.selectedCourseData.NameCourse!,
        typeCourse: this.selectedCourseData.getTypeCourseName(),
        showRegMinE: !(this.selectedCourseData.NoRegisterMinE == null || this.selectedCourseData.NoRegisterMinE == undefined),
        regMinE: this.selectedCourseData.getNoRegisterMinE(),
        showRegMil: !(this.selectedCourseData.NoRegisterMiL == null || this.selectedCourseData.NoRegisterMiL == undefined),
        regMil: this.selectedCourseData.getNoRegisterMil(),
        showDuration: !(this.selectedCourseData.typeCourseDurationID == null || this.selectedCourseData.typeCourseDurationID == undefined),
        duration: this.selectedCourseData.getDuration(),
        description: this.selectedCourseData.getDescription(),
        state: this.selectedCourseData.getState(),
        TeachingHoursMin: ''+this.selectedCourseData.TeachingHoursmin!,
        TypeRecord: this.selectedCourseData.TypeRecord!,
        DesiredGrade: ''+this.selectedCourseData.DesiredGrade!,
      }
    };
  }

  onSchoolSelectChange(optionSelected: IDropdownItem): void {
    this.filtersData.dataSelected.area = Number(optionSelected.value);
    this.applyFilters();
  }

  onStringQuerySelectChange(optionSelected: any): void {
    this.filtersData.dataSelected.inputText = optionSelected.target.value;
    this.applyFilters();
  }

  applyFilters(): void {
    if (!this.selectedCourseData.SubjectsV2) {
      this._sweetalertService.swalError('', 'No hay materias para filtrar', ()=>{});
      return
    }
    this.subjectsToShow = this.selectedCourseData.SubjectsV2?.filter(subjectV2Iterated => (
      (
        this.filtersData.dataSelected.area == -1 ||
        this.filtersData.dataSelected.area == subjectV2Iterated.AcademicaAreaCourseID
      ) &&
      (
        this.filtersData.dataSelected.inputText.trim() == '' ||
        subjectV2Iterated.nameSubject!.toLowerCase().includes(this.filtersData.dataSelected.inputText.trim().toLowerCase())
      )
    ));
    if (this.subjectsToShow.length == 0) this._sweetalertService.swalError('', 'No hay datos para mostrar', ()=>{});
  }

  closeDetails() {
    this.responseCloseDetails.emit();
  }

  showAreaModal(value: boolean, index: number) {
    this.responseShowAreaModal.emit({isNew: value, index: index});
  }

  showSubjectModal(value: boolean, index: number) {
    this.responseShowSubjectModal.emit({isNew: value, index: index});
  }

  showOfferModal() {
    this.responseShowOfferModal.emit({isNew: true, index: this.selectedCourseData.id!});
  }

  showCourseModal() {
    this.responseShowCourseModal.emit({isNew: false, index: this.selectedCourseData.id!});
  }

}

/** TODO: move to a new file */
enum enumTypeTabViewCourseDetails {
  DETAILS,
  AREAS,
  SUBJECTS,
}

interface SubjectsV2FiltersData {
  dataSelected: {
    area: number,
    inputText: string,
  };
}