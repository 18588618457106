<div class="areaCourseDetailsCMainContainer">
  <div class="sectionDescription">
    <div class="descriptionSection_left">
      <span class="descriptionTittle">Nombre del área</span><br>
      <span>{{ academicAreaDataToShow.name }}</span>
    </div>
    <div class="descriptionSection_center">
      <span class="descriptionTittle">Descripción del área</span><br>
      <span>{{ academicAreaDataToShow.description }}</span>
    </div>
    <div class="descriptionSection_right">
      <!-- <button class="invisibleBtn" (click)="showAreaModal()">
        <em class="icon-i-pencil editIcon"></em>
      </button> -->
    </div>
  </div>
  <div class="sectionSubjects">
    @for (subjectIterated of academicAreaData.SubjectsV2; track subjectIterated.id) {
      <standard-subject-v2-course-details
        [subjectData]="subjectIterated"
        [areaData]="academicAreaData"
        [teachingHoursMin]="10"
      ></standard-subject-v2-course-details><!-- TODO: teachingHoursMin -->
    } @empty {
      <div class="sectionSubjects_noSubjects">
        <span>No hay materias registradas para esta área.</span>
      </div>
    }
  </div>
</div>
