import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IDropdownItem } from '@components/ui/models/dropdown-item';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { UiSelectContentComponent } from '@components/ui/ui-select/ui-select-content/ui-select-content.component';
import { UiSelectItemComponent } from '@components/ui/ui-select/ui-select-item/ui-select-item.component';
import { UiSelectComponent } from '@components/ui/ui-select/ui-select.component';
import { UiTextareaComponent } from '@components/ui/ui-textarea/ui-textarea.component';
import { AcademicaArea } from '@models/db/AcademicaArea';
import { School } from '@models/db/school';
import { SubjectV2 } from '@models/db/SubjectV2';
import { ApiService } from '@services/api-service.service';
import { SweetalertService } from '@services/sweetalert.service';
import { forkJoin, take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'manage-subjects',
  standalone: true,
  imports: [
    // Modulos de Jhan
    UiInputComponent,
    UiSelectComponent,
    UiSelectContentComponent,
    UiSelectItemComponent,
    UiTextareaComponent,
    // Angular modulos
    FormsModule,
  ],
  templateUrl: './manage-subjects.component.html',
  styleUrl: './manage-subjects.component.css'
})
export class ManageSubjectsComponent {

  @Input() isNewData!: boolean;
  @Input() receivedSubjectData!: SubjectV2;
  @Output() eventCloseModal = new EventEmitter<void>();
  @Output() eventUpdateDataAndCloseModal = new EventEmitter<void>();

  protected ALL_SCHOOL_LIST!: School[];
  protected AREA_TO_SHOW_IN_SELECT: AcademicaArea[] = [];
  protected schoolIdSelectedOnSelect: number = 0;

  protected localSubjectData: SubjectV2 = {
    nameSubject: '',
    academicAreaID: 0,
    description: '',
  };
  protected DATA_IN_HTML = {
    tittle: '',
    schoolSelect: '',
    btnText: '',
  };

  constructor (
    private _apiService: ApiService,
    private _sweetalertService: SweetalertService,
  ) {
    this.ALL_SCHOOL_LIST = this._apiService.ALL_SCHOOLS();
  };

  ngOnInit(): void {
    this.DATA_IN_HTML = {
      tittle: ( this.isNewData ? 'Crear' : 'Editar' ),
      schoolSelect: `Seleccionar ${environment.APP_DATA.SCHOOL_TEXT}`,
      btnText: ( this.isNewData ? 'Crear' : 'Actualizar' ),
    };
    if (this.isNewData) {
      this.localSubjectData = {
        nameSubject: '',
        academicAreaID: 0,
        description: '',
      };
    } else if (this.receivedSubjectData) {
      this.localSubjectData = this.receivedSubjectData;
      this.schoolIdSelectedOnSelect = this.receivedSubjectData.AcademicaArea?.SchoolID!;
      this.onSchoolSelectChange({value: ''+this.schoolIdSelectedOnSelect, label: ''});
    } else this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{ this.closeModal() }); // Flujo EDITAR programa con ERROR al recibir el programa para editar
  }

  /* ****************
   * OUTPUT FUNCTIONS
   * **************** */

  protected closeModal(): void {
    this.eventCloseModal.emit();
  }

  protected updateDataAndCloseModal(): void {
    this.eventUpdateDataAndCloseModal.emit();
  }

  protected confirmProcessToValidateData(): void {
    this._sweetalertService.swalQuestion(
      'Confirmar acción',
      `¿Esta seguro de guardar esta área?`,
      () => {
        this.validateDataBeforeSendingReq()
      }
    );
  }

  private validateDataBeforeSendingReq(): void {
    let dataValidated = {
      isOk: true,
      msg: ''
    };
    if (
      this.localSubjectData.nameSubject?.trim() == '' ||
      this.localSubjectData.nameSubject!.trim().length < 4
    ) {
      dataValidated.isOk = false;
      dataValidated.msg += 'Nombre invalido. ';
    }
    if ( this.localSubjectData?.academicAreaID! <= 0 ) {
      dataValidated.isOk = false;
      dataValidated.msg += `Seleccione ${environment.APP_DATA.SCHOOL_TEXT}. `;
    }
    if (
      this.localSubjectData.description?.trim() == '' ||
      this.localSubjectData.description!.trim().length < 4
    ) {
      dataValidated.isOk = false;
      dataValidated.msg += 'Descripción invalida. ';
    }
    
    if (!dataValidated.isOk) {
      this._sweetalertService.swalError('Error', dataValidated.msg, ()=>{});
      return;
    }
    if (this.isNewData) this.saveData();
    else this.updateData();
  }

  private saveData(): void {
    const dataToCreate = {
      path: `SubjectsV2`,
      data: this.localSubjectData
    };

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      NEW_SUBJECT_00: this._apiService.post( dataToCreate ).pipe( take( 1 ), ),
    }).subscribe({
      next: (responseCreate) => {
        console.log('responseCreate: ', responseCreate);
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han guardado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("createNewCourse error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });
  }

  private updateData(): void {
    const dataToUpdate = {
      path: `SubjectsV2/${this.receivedSubjectData.id!}`,
      data: this.localSubjectData
    };

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      UPDATE_SUBJECT_00: this._apiService.patch( dataToUpdate ).pipe( take(1) ),
    }).subscribe({
      next: (responseCreate) => {
        console.log('responseCreate: ', responseCreate);
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han guardado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("createNewCourse error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });

  }

  onSchoolSelectChange(optionSelected: IDropdownItem): void {
    let SchoolIdSelected = Number(optionSelected.value);
    let schoolSelectedData = this.ALL_SCHOOL_LIST.find( x => x.id == SchoolIdSelected )
    this.AREA_TO_SHOW_IN_SELECT = [];
    if (!schoolSelectedData?.AcademicAreas) return;
    this.AREA_TO_SHOW_IN_SELECT.push( ...schoolSelectedData.AcademicAreas );
  }

}
