import { AcademicaAreaCourse } from "./academicaAreaCourse";
import { School } from "./school";
import { Subject } from "./Subject";
import { SubjectV2Course } from "./SubjectV2Course";
import { TypeCourse } from "./typeCourse";
import { TypeCourseDuration } from "./TypeCourseDuration";

export class Course {
  id?: number;
  NameCourse?: string;
  TypeCourseID?: number;
  SchoolID?: number;
  DurationTotal?: number;
  Description?: string;
  NoRegisterMinE?: number;
  NoRegisterMiL?: string;
  IsMilitar?: boolean;
  IsSemestralize?: boolean;
  TeachingHoursmin?: number;
  totalSemester?: number;
  durationSemester?: number;
  IsActive?: boolean;
  requirements?: string;
  profiles?: number;
  CourseID?: number;
  IsBase?: boolean;
  DesiredGrade?: number;
  IsAnnual?: boolean;
  TypeRecord?: string;
  forces?: string;
  typeCourseDurationID?: number;
  infoMidterms?: string;
  createdBy?: number;
  modifiedBy?: number;
  modifiedAt?: string;
  createdAt?: string;
  version?: number;
  /** ********* */
  /** RELATIONS */
  /** ********* */
  School?: School;
  TypeCourse?: TypeCourse;
  Subjects?: Subject[]; // Materias para la versión 1
  SubjectsV2?: SubjectV2Course[]; // Materias para la versión 2
  AcademicAreasCourse?: AcademicaAreaCourse[];
  TypeCourseDuration?: TypeCourseDuration;

  constructor (data?: Partial<Course>) {
    Object.assign(this, data);
  }

  /** ******* */
  /** METHODS */
  /** ******* */
  public getNoRegisterMinE(): string {
    let response = 'N/A';
    if ( this.NoRegisterMinE ) response = `${this.NoRegisterMinE}`;
    return response;
  }

  public getNoRegisterMil(): string {
    let response = 'N/A';
    if ( this.NoRegisterMiL ) response = `${this.NoRegisterMiL}`;
    return response;
  }

  public getDescription(): string {
    let response = 'Sin descripción';
    if ( this.Description ) response = `${this.Description}`;
    return response;
  }

  /**
   * **Note:**
   * if you dont have "TypeCourseDuration" relation, you will get a default response
   */
  public getDuration(): string {
    let response = 'N/A';
    if ( this.TypeCourseDuration ) response = `${this.TypeCourseDuration.nameTypeDuration}`;
    return response;
  }

  public getSemesters(): string {
    let response = 'N/A';
    if ( this.durationSemester ) response = `${this.durationSemester}`;
    return response;
  }

  /**
   * **Note:**
   * if you dont have "School" relation, you will get a default response
   */
  public getSchoolAcronimAndSchoolName(): string {
    let response = 'Cargando nombre...';
    if ( this.School ) response = `${this.School.SchoolAcronim} - ${this.School.NameTSchool}`;
    return response;
  }

  /**
   * **Note:**
   * if you dont have "School" relation, you will get a default response
   */
  public getSchoolIconUrl(): string {
    /** TODO: default icon = 'https://storage.googleapis.com/celic_assets/schools/logo/1.svg' */
    let response = '';
    if ( this.School ) response = `${this.School.Icono}`;
    return response;
  }

  /**
   * **Note:**
   * if you dont have "TypeCourse" relation, you will get a default response
   */
  public getTypeCourseName(): string {
    let response = 'N/A';
    if ( this.TypeCourse ) response = `${this.TypeCourse.NameTypeCourse}`;
    return response;
  }

  public getState(): string {
    let response = 'Inactivo';
    if ( this.IsActive ) response = `Activo`;
    return response;
  }

}
