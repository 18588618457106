import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IDropdownItem } from '@components/ui/models/dropdown-item';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { UiSelectContentComponent } from '@components/ui/ui-select/ui-select-content/ui-select-content.component';
import { UiSelectItemComponent } from '@components/ui/ui-select/ui-select-item/ui-select-item.component';
import { UiSelectComponent } from '@components/ui/ui-select/ui-select.component';
import { AcademicaAreaCourse } from '@models/db/academicaAreaCourse';
import { AcademicPerformance } from '@models/db/AcademicPerformance';
import { Category } from '@models/db/category';
import { Course } from '@models/db/course';
import { School } from '@models/db/school';
import { SubjectV2Course } from '@models/db/SubjectV2Course';
import { TypeCourse } from '@models/db/typeCourse';
import { TypeCourseDuration } from '@models/db/TypeCourseDuration';
import * as ALL_ENUMS from '@models/logicModels/mallas/enumsMallas';
import { CourseInfoMidTermProperties, ManageCourseModalDataToShow, ManagementAreaCourseToShow, ManagementCourseDataToSave, ManagementSubjectAreasCourseToShow, ManageTabsCourseModalProperties } from '@models/logicModels/mallas/modalsData';
import { ApiService } from '@services/api-service.service';
import { SweetalertService } from '@services/sweetalert.service';
import { concat, forkJoin, map, Observable, of, switchMap, take, toArray } from 'rxjs';
import { UiTextareaComponent } from "../../../ui/ui-textarea/ui-textarea.component";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'modal-manage-course',
  standalone: true,
  imports: [
    /** Jhan components */
    UiInputComponent,
    UiSelectComponent,
    UiSelectContentComponent,
    UiSelectItemComponent,
    UiTextareaComponent,
    /** Angular */
    FormsModule,
    CommonModule,
  ],
  templateUrl: './manage-course.component.html',
  styleUrl: './manage-course.component.css'
})
export class ManageCourseComponent {

  @Input() isNewData!: boolean;
  @Input() courseData!: Course;
  @Input() allSchoolsListData!: School[];
  @Input() allTypeCoursesListData!: TypeCourse[];
  @Output() eventCloseModal = new EventEmitter<void>();
  @Output() eventUpdateDataAndCloseModal = new EventEmitter<void>();

  /** DB Data to selects */
  protected ALL_TYPE_COURSES_DURATION_LIST: TypeCourseDuration[] = [];
  protected ALL_CATEGORIES_LIST: Category[] = [];
  protected TYPE_COURSES_TO_SHOW: TypeCourse[] = [];
  /** Mamage localData */
  protected AllInfoMidTermsCourse: CourseInfoMidTermProperties[] = [ { midtermNum: 1, percentage: 100, description: "Descripción", professorCanEditPercentage: false, } ];
  protected localCourseData: Course = new Course();
  /** Other data */
  protected localCourseData_categoryID: number = 0; /** Revisar esta variable */
  protected areaManagementToShow: ManagementAreaCourseToShow[] = [];
  protected localSchoolDataSelected: School | undefined = undefined;
  protected dataToShow: ManageCourseModalDataToShow = {
    tittle: '',
    subTittle: '',
    btnText: '',
    schoolFilterTittle: '',
  };
  protected MANAGE_TABS: ManageTabsCourseModalProperties = {
    allOptions: ALL_ENUMS.enumCourseModalTabsOptions,
    index: ALL_ENUMS.enumCourseModalTabsOptions.DETAILS,
  };
  private dataToSave: ManagementCourseDataToSave = {
    data: {
      areas: [],
    },
    quantity: {
      areas: 0,
      subjects: 0,
    },
  }

  constructor (
    private _apiService: ApiService,
    private _sweetalertService: SweetalertService,
  ) {
    this.ALL_TYPE_COURSES_DURATION_LIST = this._apiService.ALL_TYPE_COURSES_DURATION();
    this.ALL_CATEGORIES_LIST = this._apiService.ALL_CATEGORIES_LIST();
  };

  ngOnInit(): void {
    this.dataToShow = {
      tittle: ( this.isNewData ? 'Crear nuevo programa' : 'Editar programa' ),
      subTittle: ( this.isNewData ? 'Agrega' : 'Edita' ),
      btnText: ( this.isNewData ? 'Crear' : 'Actualizar' ),
      schoolFilterTittle: environment.APP_DATA.SCHOOL_TEXT,
    };
    this.areaManagementToShow = [];
    if (this.isNewData) { // Flujo NUEVO programa
      this.localCourseData = new Course();
      this.localCourseData.version = 2;
    } else if (this.courseData) { // Flujo EDITAR programa
      this.localCourseData = this.courseData;
      this.AllInfoMidTermsCourse = JSON.parse(JSON.stringify(this.localCourseData.infoMidterms));
      this.onSchoolSelectChange({value: ''+this.localCourseData.SchoolID!, label: ''}, true);
    } else this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{ this.closeModal() }); // Flujo EDITAR programa con ERROR al recibir el programa para editar
  }

  /* ****************
   * OUTPUT FUNCTIONS
   * **************** */

  protected closeModal(): void {
    this.eventCloseModal.emit();
  }

  protected updateDataAndCloseModal(): void {
    this.eventUpdateDataAndCloseModal.emit();
  }

  protected confirmProcessToValidateData(): void {
    this.dataToSave.data.areas = this.areaManagementToShow.filter( x => x.isSelected || x.isSavedOnDB );
    this.dataToSave.quantity.areas = this.dataToSave.data.areas.length;
    this.dataToSave.quantity.subjects = 0;
    this.dataToSave.data.areas.forEach(areaSelectedIterated => {
      let subjectsSelected = areaSelectedIterated.allSubjectList.filter( x => x.isSelected );
      this.dataToSave.quantity.subjects += subjectsSelected.length;
    });
    this._sweetalertService.swalQuestion(
      'Confirmar acción',
      `¿Esta seguro de guardar este programa con ${this.dataToSave.quantity.areas} áreas y ${this.dataToSave.quantity.subjects} materias?`,
      () => {
        this.validateDataBeforeSendingReq()
      }
    );
  }

  protected validateDataBeforeSendingReq(): void {
    /** TODO: move to another function y validar */
    this.localCourseData.DesiredGrade = Number(this.sanitizeNumber(String(this.localCourseData.DesiredGrade)));


    console.log('validateDataBeforeSendingReq');
    console.log('localCourseData: ', this.localCourseData);
    let isOk = true;
    isOk = this.validateCourseDataBeforeSave();
    if (this.isNewData) {
      /** TODO: hacer validaciones de campos */
      /** TODO: Semestre solo en edu superior */
      /** TODO: RegEduSupsolo en edu superior */
      /** TODO: es base y curso solo en edu militar */
      /** TODO: RegEduMil en edu superior */
      if (isOk) this.createNewCourse();
      else this._sweetalertService.swalError('Error', 'Datos incompletos', ()=>{});
    } else {
      /** TODO: hacer validaciones de campos */
      /** TODO: hacer validaciones de si hay campos editados */
      if (isOk) this.editCourseSelected();
    }
  }

  private sanitizeNumber(input: string): string {
    // Reemplazar la primera coma por un punto
    let sanitized = input.replace(',', '.');
  
    // Encontrar la primera ocurrencia de punto
    const firstDotIndex = sanitized.indexOf('.');
  
    if (firstDotIndex !== -1) {
      // Ignorar cualquier carácter después del segundo punto o coma
      sanitized = sanitized.substring(0, firstDotIndex + 1) + sanitized.substring(firstDotIndex + 1).replace(/[.,]/g, '');
    }
  
    return sanitized;
  }

  private validateCourseDataBeforeSave(): boolean {
    let result: boolean = true;
    let midTerPercentTotal = 0;
    let midTermsValids = true;
    this.AllInfoMidTermsCourse.forEach(midTermIterated => {
      console.log('midTermIterated', midTermIterated);
      console.log('midTerPercentTotal', midTerPercentTotal);
      if (midTermIterated.percentage && midTermIterated.percentage != 0) midTerPercentTotal += Number(midTermIterated.percentage);
      else midTermsValids = false;
    });
    if (midTerPercentTotal != 100) {
      result = false;
      this._sweetalertService.swalError('Error', `La suma de los porcentajes de todas notas debe ser igual a 100, la suma actual es: ${midTerPercentTotal}`, ()=>{});
    }
    if (!midTermsValids) {
      result = false;
      this._sweetalertService.swalError('Error', `Los porcentajes deben ser mayores a 0`, ()=>{});
    }
    return result;
  }

  /* ******************************
   * FLUJO CREAR PROGRAMA ACADEMICO 
   * ****************************** */

  protected createNewCourse(): void {
    /** Course -> AcademicaAreaCourses -> SubjectV2Courses -> AcademicPerformance */
    console.log('createNewCourse');
    this.localCourseData.infoMidterms = JSON.stringify(this.AllInfoMidTermsCourse);
    this.localCourseData.createdBy = this._apiService.userInfo()!.id!;
    const dataToCreate = {
      path: `Courses`,
      data: this.localCourseData
    };

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      NEW_COURSE_00: this._apiService.post( dataToCreate ).pipe(
        take( 1 ),
        switchMap( responseCourse => this.createAreasAfterCourseCreated(responseCourse) ),
      ),
    }).subscribe({
      next: (responseCreate) => {
        console.log('responseCreate: ', responseCreate);
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han guardado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("createNewCourse error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });
  }

  private createAreasAfterCourseCreated(responseCourse: Course): Observable<any> {
    let areaObserbable: any = [];
    this.dataToSave.data.areas.forEach(areaIterated => {
      let dataArea = {
        academicaAreaID: areaIterated.idArea,
        courseID: responseCourse.id
      };
      let dataReq = {
        path: "/AcademicaAreaCourses",
        data: dataArea
      };
      areaObserbable.push( this._apiService.post<AcademicaAreaCourse>( dataReq ).pipe(
        take(1),
        switchMap( responseArea => this.createSubjectsV2AfterAreaCreated(responseArea, areaIterated) ),
      ) );
    });
    if (areaObserbable.length == 0) return of([]);
    return concat(...areaObserbable).pipe(
      toArray(),
      map((responseAcadAreaCourse)=>({responseCourse, responseAcadAreaCourse}))
    );
  }

  private createSubjectsV2AfterAreaCreated(responseArea: AcademicaAreaCourse, areaIterated: ManagementAreaCourseToShow): Observable<any> {
    let subjectAreaObserbable: any = [];
    areaIterated.allSubjectList.forEach(subjectIterated => {
      if (!subjectIterated.isSelected) return; // Si no esta seleccionada la materia, la quito
      let dataSubject: SubjectV2Course = this.getNewSubjectV2CourseData(subjectIterated, responseArea.id!, responseArea.courseID!);
      let dataReqSubj = { path: "/SubjectV2Courses", data: dataSubject };
      subjectAreaObserbable.push( this._apiService.post( dataReqSubj ).pipe(
        take(1),
        switchMap(responseSubject => this.createAcademicPerformanceAfterSubjectV2Created(responseSubject, subjectIterated) ),
      ) );
    });
    if (subjectAreaObserbable.length == 0) return of([]);
    return concat(...subjectAreaObserbable).pipe(
      toArray(),
      map((responseAreaSubject)=>({responseArea, responseAreaSubject}))
    );
  }

  private createAcademicPerformanceAfterSubjectV2Created(responseSubject: SubjectV2Course, subjectIterated: ManagementSubjectAreasCourseToShow): Observable<any> {
    let academicPerformanceObserbable: any = [];
    subjectIterated.academicPerformances.forEach(desempenhoIterated => {
      desempenhoIterated.subjectV2CourseID = responseSubject.id;
      let dataAcademicPerf = {
        path: "/AcademicPerformances",
        data: desempenhoIterated
      };
      academicPerformanceObserbable.push( this._apiService.post( dataAcademicPerf ).pipe( take(1) ) );
    });
    if (academicPerformanceObserbable.length == 0) return of([]);
    return concat(...academicPerformanceObserbable).pipe(
      toArray(),
      map((responseAcademicPerformance)=>({responseSubject, responseAcademicPerformance}))
    );
  }

  /** 
   * *******************************
   * FLUJO EDITAR PROGRAMA ACADEMICO 
   * *******************************
   */

  protected editCourseSelected(): void {
    console.log('editCourseSelected');
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');

    this.localCourseData.infoMidterms = JSON.stringify(this.AllInfoMidTermsCourse);
    this.localCourseData.modifiedBy = this._apiService.userInfo()!.id!;
    this.localCourseData.modifiedAt = new Date().toISOString();

    const dataToUpdate = {
      path: `Courses/${this.courseData.id!}`,
      data: this.localCourseData
    };

    let objetWiathAllDataToUpdate: any = {
      UPDATE_COURSE: this._apiService.patch( dataToUpdate ).pipe( take(1) ),
    };

    let indexCounters = {
      area: { create: 0, update: 0, delete: 0, },
      subject: { create: 0, update: 0, delete: 0, },
      performance: { create: 0, update: 0, delete: 0, },
    };

    this.dataToSave.data.areas.forEach(areaIterated => {
      if (areaIterated.isSelected && areaIterated.isSavedOnDB) { // Se actualiza area
        areaIterated.allSubjectList.forEach(subjectIterated => {
          if (subjectIterated.isSelected && subjectIterated.isSavedOnDB) { // Se actualiza subject
            let dataReqSubj = { path: `/SubjectV2Courses/${subjectIterated.subjectData.id}`, data: subjectIterated.subjectData };
            objetWiathAllDataToUpdate[`UPDATE_SUBJECT_${ String(indexCounters.subject.update).padStart(3, '0') }`] = this._apiService.patch( dataReqSubj ).pipe( take(1) );
            let subjectFinded = this.courseData.SubjectsV2?.find( x => x.subjectV2ID == subjectIterated.idSubject);
            subjectIterated.academicPerformances.forEach( desempenhoIterated => {
              if ( desempenhoIterated.id ) { // Editar desempeño
                let dataAcademicPerf = { path: `/AcademicPerformances/${desempenhoIterated.id}`, data: desempenhoIterated };
                objetWiathAllDataToUpdate[`UPDATE_ACADEMICPERFORMANCE_${ String(indexCounters.performance.update).padStart(3, '0') }`] = this._apiService.patch( dataAcademicPerf ).pipe( take(1) );
                indexCounters.performance.update++;
              } else { // crear desempeño
                desempenhoIterated.subjectV2CourseID = subjectFinded?.id;
                let dataAcademicPerf = { path: `/AcademicPerformances`, data: desempenhoIterated };
                objetWiathAllDataToUpdate[`CREATE_ACADEMICPERFORMANCE_${ String(indexCounters.performance.create).padStart(3, '0') }`] = this._apiService.post( dataAcademicPerf ).pipe( take(1) );
                indexCounters.performance.create++;
              }
            });
            subjectFinded?.AcademicPerformances?.forEach( desempenhosExistentesIterados => {
              let desempenhoToDelete = subjectIterated.academicPerformances.find( x => x.id == desempenhosExistentesIterados.id );
              if (!desempenhoToDelete) {
                let dataAcademicPerf = { path: `/AcademicPerformances/${desempenhosExistentesIterados.id}` };
                objetWiathAllDataToUpdate[`DELETE_ACADEMICPERFORMANCE_${ String(indexCounters.performance.delete).padStart(3, '0') }`] = this._apiService.delete( dataAcademicPerf ).pipe( take(1) );
                indexCounters.performance.delete++;
              }
            });
          } else if (subjectIterated.isSelected && !subjectIterated.isSavedOnDB) { // Se crea subject
            let academicAreaFinded = this.courseData.AcademicAreasCourse?.find( x => x.academicaAreaID == areaIterated.idArea && x.courseID == this.courseData.id);
            let idArea = academicAreaFinded?.id;
            let dataSubject: SubjectV2Course = this.getNewSubjectV2CourseData(subjectIterated, idArea!, this.courseData.id!);
            let dataReqSubj = { path: "/SubjectV2Courses", data: dataSubject };
            objetWiathAllDataToUpdate[`CREATE_SUBJECT_${ String(indexCounters.subject.create).padStart(3, '0') }`] = this._apiService.post( dataReqSubj ).pipe(
              take(1),
              switchMap(responseSubject => this.createAcademicPerformanceAfterSubjectV2Created(responseSubject, subjectIterated) ),
            );
            indexCounters.subject.create++;
          } else if (!subjectIterated.isSelected && subjectIterated.isSavedOnDB) { // Se borra subject
            let subjectFinded = this.courseData.SubjectsV2?.find( x => x.subjectV2ID == subjectIterated.idSubject);
            let idToDelete = subjectFinded?.id;
            let dataReq = { path: `/SubjectV2Courses/${idToDelete}` };
            objetWiathAllDataToUpdate[`DELETE_SUBJECT_${ String(indexCounters.subject.delete).padStart(3, '0') }`] = this._apiService.delete( dataReq ).pipe( take(1) );
            indexCounters.subject.delete++;
          }
        });
      } else if (areaIterated.isSelected && !areaIterated.isSavedOnDB) { // Se crea area
        let dataArea = { academicaAreaID: areaIterated.idArea, courseID: this.courseData.id };
        let dataReq = { path: "/AcademicaAreaCourses", data: dataArea };
        objetWiathAllDataToUpdate[`CREATE_AREA_${ String(indexCounters.area.create).padStart(3, '0') }`] = this._apiService.post<AcademicaAreaCourse>( dataReq ).pipe(
          take(1),
          switchMap( responseArea => this.createSubjectsV2AfterAreaCreated(responseArea, areaIterated) ),
        );
        indexCounters.area.create++;
      } else if (!areaIterated.isSelected && areaIterated.isSavedOnDB) { // Se borra area
        let academicAreaFinded = this.courseData.AcademicAreasCourse?.find( x => x.academicaAreaID == areaIterated.idArea && x.courseID == this.courseData.id);
        let idToDelete = academicAreaFinded?.id;
        let dataReq = { path: `/AcademicaAreaCourses/${idToDelete}` };
        objetWiathAllDataToUpdate[`DELETE_AREA_${ String(indexCounters.area.delete).padStart(3, '0') }`] = this._apiService.delete<AcademicaAreaCourse>( dataReq ).pipe( take(1) );
        indexCounters.area.delete++;
        /** TODO: borrar subjects y desempeños en cascada con Luis en DB */
      }
    });

    forkJoin( objetWiathAllDataToUpdate ).subscribe({
      next: (responseUpdate) => {
        console.log('editCourseSelected response: ', responseUpdate);
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han actualizado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("editCourseSelected error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });
  }

  private getNewSubjectV2CourseData(subjectIterated: ManagementSubjectAreasCourseToShow, areaID: number, courseID: number): SubjectV2Course {
    let newSubject = new SubjectV2Course();
    newSubject.nameSubject = subjectIterated.name;
    newSubject.courseID = courseID;
    newSubject.HPM = subjectIterated.subjectData.HPM;
    newSubject.HFH = subjectIterated.subjectData.HFH;
    newSubject.HTI = subjectIterated.subjectData.HTI;
    newSubject.description = subjectIterated.subjectData.description;
    newSubject.maxSlots = subjectIterated.subjectData.maxSlots;
    newSubject.creditos = subjectIterated.subjectData.creditos;
    newSubject.typeRecord = subjectIterated.subjectData.typeRecord;
    newSubject.desiredGrade = subjectIterated.subjectData.desiredGrade;
    newSubject.typeCreditID = subjectIterated.subjectData.typeCreditID;
    newSubject.isGradeByTask = subjectIterated.subjectData.isGradeByTask;
    newSubject.subjectV2ID = subjectIterated.subjectData.id;
    newSubject.AcademicaAreaCourseID = areaID;
    // eduSup_semesterNumber: 0, /** TODO: Logica pendiente para educacion superior */
    return newSubject;
  }

  onSchoolSelectChange(optionSelected: IDropdownItem, fromOnInitToEdit: boolean): void {
    console.log('onSchoolSelectChange: ', optionSelected);
    this.localSchoolDataSelected = this.allSchoolsListData.find( x => x.id == Number(optionSelected.value) );
    console.log('localSchoolDataSelected: ', this.localSchoolDataSelected);
    console.log('localCourseData: ', this.localCourseData);
    this.areaManagementToShow = [];
    this.localSchoolDataSelected?.AcademicAreas?.forEach(localAreaIterated => {
      let tempAreaBool = ( this.isNewData ? false : this.getConfirmAreaSelectedByAreaID( localAreaIterated.id! ) );
      let dataArea: ManagementAreaCourseToShow = {
        isSelected: tempAreaBool,
        isSavedOnDB: tempAreaBool,
        name: localAreaIterated.NameArea!,
        idArea: localAreaIterated.id!,
        academicAreaData: localAreaIterated,
        allSubjectList: [],
      };
      localAreaIterated.SubjectsV2?.forEach(localSubjectIterated => {
        let tempSubjectBool = ( this.isNewData ? false : this.getConfirmSubjectSelectedBySubjectID( localSubjectIterated.id! ) );
        let localSubjectDataFinded: SubjectV2Course = new SubjectV2Course();
        if (tempSubjectBool) localSubjectDataFinded = this.getSubjectV2CourseDataConfirmedBySubjectID( localSubjectIterated.id! );
        let dataSubject: ManagementSubjectAreasCourseToShow = {
          isSelected: tempSubjectBool,
          isSavedOnDB: tempSubjectBool,
          name: `${localSubjectIterated.nameSubject!}`,
          idSubject: localSubjectIterated.id!,
          subjectData: ( tempSubjectBool ? localSubjectDataFinded : localSubjectIterated ),
          academicPerformances: []
        };
        if (
          (!fromOnInitToEdit && dataSubject.subjectData.isGradeByTask) ||
          (fromOnInitToEdit && localSubjectDataFinded.isGradeByTask)
        ) {
          let academicPerformanceData: AcademicPerformance = {
            descripionPerformance: '',
            performanceNum: 1,
            percentage: 100,
            midtermNum: 1, // TODO: validar este dato con Luis
          };
          if (tempSubjectBool) dataSubject.academicPerformances = this.getAcademicPerformanceToEdit(localSubjectIterated.id!);
          else dataSubject.academicPerformances.push(academicPerformanceData);
        }
        dataArea.allSubjectList.push(dataSubject);
      });

      this.areaManagementToShow.push(dataArea);
    });
    console.log('localSchoolDataSelected: ', this.localSchoolDataSelected);
    console.log('areaManagementToShow: ', this.areaManagementToShow);
  }

  getConfirmAreaSelectedByAreaID( areaID: number): boolean {
    let index = this.localCourseData?.AcademicAreasCourse?.findIndex( x => x.academicaAreaID == areaID );
    return (index != undefined && index >= 0);
  }

  getConfirmSubjectSelectedBySubjectID( subjectID: number): boolean {
    const exists = this.localCourseData?.AcademicAreasCourse?.some(area => 
      area.SubjectsV2?.some(subject => subject.subjectV2ID === subjectID)
    );
    return exists ?? false;
  }

  getSubjectV2CourseDataConfirmedBySubjectID( subjectID: number): SubjectV2Course {
    const subject = this.localCourseData?.AcademicAreasCourse?.find(area => 
      area.SubjectsV2?.find(subject => subject.subjectV2ID === subjectID)
    )?.SubjectsV2?.find(subject => subject.subjectV2ID === subjectID);
    return subject!;
  }

  getAcademicPerformanceToEdit( idSubjectToFind: number ): AcademicPerformance[] {
    let foundSubject: SubjectV2Course | null = null;
    this.localCourseData?.AcademicAreasCourse?.some(area => {
      return area.SubjectsV2?.some(subject => {
        if (subject.subjectV2ID === idSubjectToFind) {
          foundSubject = subject; // Se guarda la materia encontrada
          return true; // Se detiene la búsqueda en este punto
        }
        return false;
      });
    });
    let dataString: string = '[]';
    if (foundSubject != null) dataString = JSON.stringify(foundSubject['AcademicPerformances']);
    const dataArray = JSON.parse(dataString);
    const academicPerformanceList: AcademicPerformance[] = dataArray.map((item: any) => {
      return Object.assign(new AcademicPerformance(), item);
    });
    return academicPerformanceList;
  }

  /** **************************************************************** */
  /** **************************************************************** */
  /** ****************************** NO ****************************** */
  /** **************************************************************** */
  /** **************************************************************** */

  protected confirmDeleteNotesSectionSelected(indexNoteClicked: number): void {
    if (this.AllInfoMidTermsCourse.length <= 1) return;
    this.AllInfoMidTermsCourse.splice(indexNoteClicked, 1);
    this.AllInfoMidTermsCourse.forEach((element, index) => {
      element.midtermNum = (index + 1);
    });
  }

  protected addNewNoteSection(): void {
    let newData = {
      midtermNum: (this.AllInfoMidTermsCourse.length + 1),
      percentage: 0,
      description: "",
      professorCanEditPercentage: true,
    };
    this.AllInfoMidTermsCourse.push(newData);
  }

  protected confirmDeleteAcademicPerformanceSectionSelected(subjectIterated: ManagementSubjectAreasCourseToShow, indexNoteClicked: number): void {
    if (subjectIterated.academicPerformances.length <= 1) return;
    subjectIterated.academicPerformances.splice(indexNoteClicked, 1);
    subjectIterated.academicPerformances.forEach((element, index) => {
      element.performanceNum = (index + 1);
    });
  }

  protected addNewAcademicPerformanceSection(subjectIterated: ManagementSubjectAreasCourseToShow, midtermNumReceived: number): void {
    let actualPerformancesOfThisMidterm = subjectIterated.academicPerformances.filter( x=> x.midtermNum == midtermNumReceived );
    let academicPerformanceData: AcademicPerformance = {
      descripionPerformance: '',
      performanceNum: (actualPerformancesOfThisMidterm.length + 1),
      percentage: 100,
      midtermNum: midtermNumReceived,
    };
    subjectIterated.academicPerformances.push(academicPerformanceData);
  }

  onCategorySelectChange(optionSelected: IDropdownItem): void {
    this.localCourseData.TypeCourseID = undefined;
    this.TYPE_COURSES_TO_SHOW = this.allTypeCoursesListData.filter(x => x.CategoryID == Number(optionSelected.value));
  }

  confirmUnselectSubjectFromManagementSubjectData(subjectIterated: ManagementSubjectAreasCourseToShow): void {
    console.log('subjectIterated: ', subjectIterated);
    this._sweetalertService.swalQuestion(
      'Confirmar acción',
      `¿Esta seguro de eliminar esta materia del programa?`,
      () => {
        subjectIterated.isSelected = false;
      }
    );
  }

}
