import { Component, Input, OnInit } from '@angular/core';
import { Course } from '@models/db/course';
import { CourseSectionDataToShow } from '@models/logicModels/mallas/dataCourseSection';

@Component({
  selector: 'course-section',
  standalone: true,
  imports: [],
  templateUrl: './course-section.component.html',
  styleUrl: './course-section.component.css'
})
export class CourseSectionComponent implements OnInit {

  @Input() courseReceived!: Course;

  public courseDataToShow!: CourseSectionDataToShow;

  constructor() { }

  ngOnInit(): void {
    this.courseDataToShow = {
      school: {
        name: this.courseReceived.getSchoolAcronimAndSchoolName(),
        url_img: this.courseReceived.getSchoolIconUrl(),
      },
      course: {
        name: this.courseReceived.NameCourse!,
        typeCourse: this.courseReceived.getTypeCourseName(),
        showRegMinE: !(this.courseReceived.NoRegisterMinE == null || this.courseReceived.NoRegisterMinE == undefined),
        regMinE: this.courseReceived.getNoRegisterMinE(),
        showRegMil: !(this.courseReceived.NoRegisterMiL == null || this.courseReceived.NoRegisterMiL == undefined),
        regMil: this.courseReceived.getNoRegisterMil(),
        showDuration: !(this.courseReceived.typeCourseDurationID == null || this.courseReceived.typeCourseDurationID == undefined),
        duration: this.courseReceived.getDuration(),
        description: this.courseReceived.getDescription(),
        state: '',
        TeachingHoursMin: '',
        TypeRecord: '',
        DesiredGrade: '',
      }
    };
  }

}
