<div class="mainContainerCourseSection">
  <div class="container_header">
    <div class="container_headerIcon">
      <img [src]="courseDataToShow.school.url_img" alt="Logo">
    </div>
    <div class="container_headerTittle">
      <div class="containerData_schoolName">
        <span>{{ courseDataToShow.school.name }}</span>
      </div>
      <div class="containerData_courseName">
        <span>{{ courseDataToShow.course.name }}</span>
      </div>
    </div>
    <div class="container_headerBtnClose">
      <button class="btnCallToAction" (click)="closeDetails()">X</button>
    </div>
  </div>
  <div class="container_data">
    <div class="containerData_tabsContainer">
      <div class="tabsContainer">
        <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': viewTabIndex == TYPE_TAP_VIEW_ENUM.DETAILS }" (click)="viewTabIndex = TYPE_TAP_VIEW_ENUM.DETAILS">Detalles del programa</button>
        <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': viewTabIndex == TYPE_TAP_VIEW_ENUM.AREAS }" (click)="viewTabIndex = TYPE_TAP_VIEW_ENUM.AREAS">Áreas</button>
        <button class="tabDefault" [ngClass]="{ 'tabDefault_selected': viewTabIndex == TYPE_TAP_VIEW_ENUM.SUBJECTS }" (click)="viewTabIndex = TYPE_TAP_VIEW_ENUM.SUBJECTS">Materias</button>
      </div>
      <div>
        @switch (viewTabIndex) {
          @case (TYPE_TAP_VIEW_ENUM.AREAS) {
            <!-- <button class="btnCallToAction" (click)="showAreaModal(true, 0)">Agregar Área</button> -->
          }
          @case (TYPE_TAP_VIEW_ENUM.SUBJECTS) {
            <!-- <button class="btnCallToAction" (click)="showSubjectModal(true, 0)">Agregar materia</button> -->
          }
        }
      </div>
    </div>
    @switch (viewTabIndex) {
      @case (TYPE_TAP_VIEW_ENUM.DETAILS) {
        <div class="containerData_courseBtns">
          <button class="btnCallToAction" (click)="showOfferModal()">Crear oferta</button>
          <button class="btnCallToAction" (click)="showCourseModal()">Editar programa</button>
        </div>
        <div class="containerData_courseData">
          <div class="courseData_infoSection">
            <strong>Tipo de curso: </strong><span>{{ courseDataToShow.course.typeCourse }}</span>
          </div>
          @if (courseDataToShow.course.showRegMinE) {
            <div class="courseData_infoSection">
              <strong>Registro Calificado No: </strong><span>{{ courseDataToShow.course.regMinE }}</span>
            </div>
          }
          @if (courseDataToShow.course.showRegMil) {
            <div class="courseData_infoSection">
              <strong>Registro Militar No: </strong><span>{{ courseDataToShow.course.regMil }}</span>
            </div>
          }
          @if (courseDataToShow.course.showDuration) {
            <div class="courseData_infoSection">
              <strong>Duración: </strong><span>{{ courseDataToShow.course.duration }}</span>
            </div>
          }
          <div class="courseData_infoSection">
            <strong>Estado: </strong><span>{{ courseDataToShow.course.state }}</span>
          </div>
          <div class="courseData_infoSection">
            <strong>Minutos de una hora cátedra: </strong><span>{{ courseDataToShow.course.TeachingHoursMin }}</span>
          </div>
          <div class="courseData_infoSection">
            <strong>Tipo de calificación: </strong><span>{{ courseDataToShow.course.TypeRecord }}</span>
          </div>
          <div class="courseData_infoSection">
            <strong>Nota mínima para aprobar: </strong><span>{{ courseDataToShow.course.DesiredGrade }}</span>
          </div>
        </div>
        <div class="containerData_courseDescript">
          <strong class="headerSection">Descripción del programa:</strong><br>
          <span>{{ courseDataToShow.course.description }}</span>
        </div>
      }
      @case (TYPE_TAP_VIEW_ENUM.AREAS) {
        @for (academicAreaIterated of selectedCourseData.AcademicAreasCourse; track academicAreaIterated.id) {
          <course-section-area
            [academicAreaData]="academicAreaIterated"
            (responseShowAreaModal)="showAreaModal(false, academicAreaIterated.id!)"
          ></course-section-area>
        } @empty {
          No hay áreas para este programa.
        }
      }
      @case (TYPE_TAP_VIEW_ENUM.SUBJECTS) {
        <div class="containerData_filtersContainer">
          <div>
            <ui-select label="Área académica" defaultValue="0" (onValueChange)="onSchoolSelectChange($event)">
              <ui-select-content>
                <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
                <ui-select-item value="-1">Todos</ui-select-item>
                @for (areaCourseIterated of selectedCourseData.AcademicAreasCourse; track areaCourseIterated.id) {
                  <ui-select-item [value]="areaCourseIterated.id!">{{ areaCourseIterated.AcademicArea?.NameArea }}</ui-select-item>
                }
              </ui-select-content>
            </ui-select>
          </div>
          <div>
            <ui-input
              variant="flat"
              label="Filtro por texto"
              placeholder="Escriba una palabra para filtrar por texto"
              (change)="onStringQuerySelectChange($event)"
            />
          </div>
        </div>
        @for (subjectIterated of this.subjectsToShow; track subjectIterated.id) {
          <course-section-subjects
            [subjectData]="subjectIterated"
            (responseShowSubjectModal)="showSubjectModal(false, subjectIterated.id!)"
          ></course-section-subjects>
        } @empty {
          No hay materias para este programa.
        }
      }
    }
  </div>
</div>
